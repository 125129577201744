import React from 'react';
import propTypes from 'prop-types';

const WaterHeaterIcon = ({size, ...props}) => {
    return (
        <svg
            width={size || '24'}
            height={size || '24'}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M12.0002 11.0349C12.6173 11.0349 13.1963 10.7958 13.6304 10.3616C14.4877 9.50434 14.502 8.11805 13.6636 7.13711L12.4754 5.74664C12.3566 5.60766 12.183 5.5277 12.0002 5.5277C11.8175 5.5277 11.6439 5.6077 11.5251 5.74664L10.3368 7.13711C9.49848 8.11809 9.51273 9.50437 10.37 10.3616C10.8041 10.7958 11.3831 11.0349 12.0002 11.0349ZM11.2871 7.94918L12.0002 7.11473L12.7134 7.94922C13.071 8.36766 13.1973 9.02707 12.7466 9.47777C12.5486 9.67582 12.2835 9.78488 12.0003 9.78488C11.717 9.78488 11.452 9.67582 11.2539 9.47777C10.8032 9.02711 10.9295 8.36766 11.2871 7.94918ZM17.003 2H6.99742C6.05703 2 5.29199 2.76508 5.29199 3.70543V18.8315C5.29199 19.7719 6.05703 20.5369 6.99742 20.5369H7.11215V21.375C7.11215 21.7202 7.39195 22 7.73715 22C8.08234 22 8.36215 21.7202 8.36215 21.375V20.5369H15.6384V21.375C15.6384 21.7202 15.9182 22 16.2634 22C16.6086 22 16.8884 21.7202 16.8884 21.375V20.5369H17.0031C17.9435 20.5369 18.7085 19.7719 18.7085 18.8315V3.70543C18.7085 2.76508 17.9434 2 17.003 2ZM17.4585 18.8315C17.4585 19.0827 17.2542 19.2869 17.003 19.2869H6.99742C6.74629 19.2869 6.54199 19.0827 6.54199 18.8315V3.70543C6.54199 3.4543 6.74629 3.25 6.99742 3.25H17.0031C17.2542 3.25 17.4585 3.4543 17.4585 3.70543V18.8315H17.4585ZM14.7037 14.6777H9.2968C8.9516 14.6777 8.6718 14.9575 8.6718 15.3027V17.5904C8.6718 17.9356 8.9516 18.2154 9.2968 18.2154H14.7037C15.0489 18.2154 15.3287 17.9356 15.3287 17.5904V15.3027C15.3287 14.9575 15.0489 14.6777 14.7037 14.6777ZM14.0787 16.9654H9.9218V15.9277H14.0787V16.9654ZM9.68039 12.9529C9.68039 12.6077 9.95988 12.3279 10.3051 12.3279H10.3057C10.6509 12.3279 10.9307 12.6077 10.9307 12.9529C10.9307 13.298 10.6509 13.5779 10.3057 13.5779C9.96047 13.5779 9.68039 13.298 9.68039 12.9529ZM14.3204 12.9529C14.3204 13.298 14.0406 13.5779 13.6954 13.5779C13.3502 13.5779 13.0701 13.298 13.0701 12.9529C13.0701 12.6077 13.3496 12.3279 13.6948 12.3279H13.6954C14.0405 12.3279 14.3204 12.6077 14.3204 12.9529Z'
                fill='#D6AD60'/>
        </svg>
    );
};

export default WaterHeaterIcon;

WaterHeaterIcon.propTypes = {
    size: propTypes.any
};
