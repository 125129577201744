import React from 'react';

const WAIcon = (props) => {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path d='M19.0262 4.90436C17.1474 3.03256 14.648 2.00107 11.988 2C9.33386 2 6.83051 3.03058 4.93933 4.90192C3.0448 6.77646 2.00049 9.26761 1.99805 11.9074V11.9104V11.9123C1.99835 13.51 2.41812 15.1218 3.21494 16.593L2.02536 22L7.49457 20.756C8.87976 21.454 10.4253 21.8221 11.9842 21.8227H11.9881C14.6418 21.8227 17.1451 20.792 19.0366 18.9205C20.9328 17.0444 21.9776 14.5565 21.9788 11.915C21.9796 9.29218 20.9312 6.8024 19.0262 4.90436ZM11.988 20.2617H11.9845C10.5848 20.2611 9.19791 19.9097 7.974 19.2452L7.71536 19.1048L4.07858 19.932L4.86853 16.3419L4.71625 16.0793C3.95911 14.7736 3.55902 13.3322 3.55902 11.9106C3.56192 7.30899 7.34274 3.56097 11.9877 3.56097C14.2316 3.56189 16.3401 4.43179 17.9246 6.01016C19.533 7.61295 20.4185 9.70981 20.4177 11.9146C20.4159 16.5172 16.6343 20.2617 11.988 20.2617Z' fill='#D6AD60' />
            <path d='M9.26968 7.54153H8.83176C8.67932 7.54153 8.43182 7.5986 8.22247 7.82642C8.01297 8.05438 7.42261 8.60538 7.42261 9.72598C7.42261 10.8466 8.24155 11.9294 8.35568 12.0815C8.46997 12.2335 9.93649 14.6065 12.2592 15.5194C14.1896 16.2781 14.5825 16.1272 15.0013 16.0892C15.4203 16.0514 16.3534 15.5384 16.5439 15.0066C16.7343 14.4748 16.7343 14.0189 16.6772 13.9237C16.62 13.8288 16.4676 13.7719 16.2391 13.658C16.0106 13.544 14.8906 12.9837 14.6811 12.9076C14.4716 12.8318 14.3193 12.7938 14.1668 13.0219C14.0144 13.2495 13.5656 13.7763 13.4323 13.9283C13.2991 14.0804 13.1657 14.0995 12.9371 13.9855C12.7086 13.8712 11.9801 13.6264 11.1071 12.8507C10.4277 12.2469 9.95618 11.4769 9.82281 11.249C9.68961 11.0211 9.80862 10.8979 9.92322 10.7842C10.0259 10.6823 10.1646 10.5428 10.2789 10.4099C10.393 10.2768 10.4255 10.1819 10.5018 10.0299C10.578 9.87796 10.5398 9.7449 10.4828 9.63107C10.4255 9.51709 9.987 8.39084 9.78375 7.9404H9.78391C9.6127 7.56107 9.4325 7.54825 9.26968 7.54153Z' fill='#D6AD60' />
        </svg>
    );
};

export default WAIcon;