import React from 'react';
import propTypes from 'prop-types';

function ShowerIcon({color, size, ...props}) {
    return (
        <svg
            width={size || '24'}
            height={size || '24'}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M9.05175 2.0047C11.8261 2.0047 14.0889 4.21241 14.1873 6.96319C17.4418 7.23079 19.9919 9.87532 20.0903 13.2007C20.0942 13.3502 20.0903 13.7713 19.5354 13.7713H7.7531C7.20609 13.7713 7.19428 13.3502 7.19822 13.2007C7.2966 9.87926 9.83488 7.2426 13.0815 6.96713C12.9831 4.82632 11.2162 3.11839 9.05569 3.11839C6.8283 3.11839 5.01806 4.92864 5.01806 7.15602V21.4451C5.01806 21.7521 4.77013 22 4.46318 22C4.15623 22 3.9083 21.7521 3.9083 21.4451V7.15209C3.9083 4.31473 6.21439 2.0047 9.05175 2.0047ZM8.3552 12.6615H18.9333C18.5791 10.0327 16.3557 8.04934 13.6443 8.04934C10.9328 8.04934 8.70938 10.0327 8.3552 12.6615Z'
                fill={color || '#787D8F'}/>
            <path
                d='M15.0809 19.8986C15.3878 19.8986 15.6357 20.1465 15.6357 20.4534V21.4412C15.6357 21.7482 15.3878 21.9961 15.0809 21.9961C14.7739 21.9961 14.526 21.7482 14.526 21.4412V20.4534C14.526 20.1465 14.7739 19.8986 15.0809 19.8986Z'
                fill={color || '#787D8F'}/>
            <path
                d='M12.2078 19.8986C12.5148 19.8986 12.7627 20.1465 12.7627 20.4534V21.4412C12.7627 21.7482 12.5148 21.9961 12.2078 21.9961C11.9009 21.9961 11.6529 21.7482 11.6529 21.4412V20.4534C11.6529 20.1465 11.9009 19.8986 12.2078 19.8986Z'
                fill={color || '#787D8F'}/>
            <path
                d='M16.5174 17.073C16.8243 17.073 17.0723 17.3209 17.0723 17.6279V18.6117C17.0723 18.9187 16.8243 19.1666 16.5174 19.1666C16.2104 19.1666 15.9625 18.9187 15.9625 18.6117V17.6279C15.9625 17.3209 16.2104 17.073 16.5174 17.073Z'
                fill={color || '#787D8F'}/>
            <path
                d='M13.6443 17.073C13.9513 17.073 14.1992 17.3209 14.1992 17.6279V18.6117C14.1992 18.9187 13.9513 19.1666 13.6443 19.1666C13.3374 19.1666 13.0895 18.9187 13.0895 18.6117V17.6279C13.0895 17.3209 13.3374 17.073 13.6443 17.073Z'
                fill={color || '#787D8F'}/>
            <path
                d='M10.7713 17.073C11.0782 17.073 11.3262 17.3209 11.3262 17.6279V18.6117C11.3262 18.9187 11.0782 19.1666 10.7713 19.1666C10.4643 19.1666 10.2164 18.9187 10.2164 18.6117V17.6279C10.2164 17.3209 10.4683 17.073 10.7713 17.073Z'
                fill={color || '#787D8F'}/>
            <path
                d='M17.9529 14.2435C18.2599 14.2435 18.5078 14.4915 18.5078 14.7984V15.7862C18.5078 16.0931 18.2599 16.3411 17.9529 16.3411C17.646 16.3411 17.3981 16.0931 17.3981 15.7862V14.7984C17.3941 14.4915 17.646 14.2435 17.9529 14.2435Z'
                fill={color || '#787D8F'}/>
            <path
                d='M15.0809 14.2435C15.3878 14.2435 15.6357 14.4915 15.6357 14.7984V15.7862C15.6357 16.0931 15.3878 16.3411 15.0809 16.3411C14.7739 16.3411 14.526 16.0931 14.526 15.7862V14.7984C14.526 14.4915 14.7739 14.2435 15.0809 14.2435Z'
                fill={color || '#787D8F'}/>
            <path
                d='M12.2078 14.2435C12.5148 14.2435 12.7627 14.4915 12.7627 14.7984V15.7862C12.7627 16.0931 12.5148 16.3411 12.2078 16.3411C11.9009 16.3411 11.6529 16.0931 11.6529 15.7862V14.7984C11.6529 14.4915 11.9009 14.2435 12.2078 14.2435Z'
                fill={color || '#787D8F'}/>
            <path
                d='M9.33868 14.2435C9.64563 14.2435 9.89355 14.4915 9.89355 14.7984V15.7862C9.89355 16.0931 9.64563 16.3411 9.33868 16.3411C9.03172 16.3411 8.7838 16.0931 8.7838 15.7862V14.7984C8.7838 14.4915 9.03172 14.2435 9.33868 14.2435Z'
                fill={color || '#787D8F'}/>
        </svg>
    );
}

export default ShowerIcon;

ShowerIcon.propTypes = {
    color: propTypes.string,
    size: propTypes.any
};