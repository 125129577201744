import { useEffect, useState } from 'react';
import propTypes from 'prop-types';

function usePagination(listData = [], items) {
    const [PageList, setPageList] = useState([]);
    const [PageNumber, setPageNumber] = useState(1);
    let renderItem = items ? items : 6;

    const totalPage = Math.ceil(listData.length / renderItem);

    useEffect(() => {
        // if (PageNumber !== totalPage) {
        loadPageList();
        // }

    }, [PageNumber, listData]);
    
    const onNext = () => {
        if (PageNumber === totalPage) return;

        if (PageNumber >= 1) {
            setPageNumber(PageNumber + 1);
        }
    };

    const onPrevious = () => {
        if (PageNumber === 1) return;

        if (PageNumber <= totalPage) {
            setPageNumber(PageNumber - 1);
        }
    };

    const loadPageList = () => {
        const generatedPageNumber = [];
        const placeholder = "...";
        const isAlmostReachedEnd = PageNumber + 3 === totalPage;
        const isTheRestIsLess = PageNumber + 3 > totalPage;

        if (totalPage > 6) {
            if (PageNumber < 5) {
                Array(totalPage).fill(null).map((_, index) => {
                    if (index < 4) {
                        generatedPageNumber.push(index + 1);
                    } else if (index === 5) {
                        generatedPageNumber.push("...");
                    } else if (index === (totalPage - 1)) {
                        generatedPageNumber.push(totalPage);
                    }
                });
            } else {
                Array(totalPage).fill(null).map((_, index) => {
                    if (index === 1) {
                        generatedPageNumber.push(1, placeholder);
                    } else if (index === PageNumber) {
                        if (isTheRestIsLess) {
                            const restNumber = [];

                            Array((PageNumber + 3) - totalPage).fill(null).map((_, index) => {
                                restNumber.push(PageNumber - (index + 1));
                            });

                            generatedPageNumber.push(...restNumber.reverse());
                        }

                        for (var i = 0; i < 3; i++) {
                            if (PageNumber + i > totalPage) return;

                            generatedPageNumber.push(PageNumber + i);
                        }

                    } else if (index === (totalPage - 1) && isAlmostReachedEnd) {
                        generatedPageNumber.push(placeholder, totalPage);
                    }
                });
            }
        } else {
            Array(totalPage).fill(null).map((_, index) => generatedPageNumber.push(index + 1));
        }

        setPageList(generatedPageNumber);
    };

    const ApplyPagination = () => {
        const maxItem = renderItem;
        const firstItemIndex = PageNumber === 1 ? 0 : (PageNumber - 1) * maxItem;
        const lastItemIndex = firstItemIndex + (maxItem - 1);
        const generatedPage = listData.filter((_, index) => index >= firstItemIndex && index <= lastItemIndex);

        return generatedPage;
    };

    return { PageList, PageNumber, ApplyPagination, onNext, onPrevious };
}

export default usePagination;

usePagination.propTypes = {
    listData: propTypes.array,
    items: propTypes.number
};