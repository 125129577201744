import React from 'react';
import propTypes from 'prop-types';

function CartIcon(props) {
    return (
        <svg
            width={25}
            height={25}
            viewBox='0 0 25 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path d='M16.75 22.5C17.7165 22.5 18.5 21.7165 18.5 20.75C18.5 19.7835 17.7165 19 16.75 19C15.7835 19 15 19.7835 15 20.75C15 21.7165 15.7835 22.5 16.75 22.5Z' 
                fill={props.fill || 'white'}/>
            <path d='M8.75 22.5C9.7165 22.5 10.5 21.7165 10.5 20.75C10.5 19.7835 9.7165 19 8.75 19C7.7835 19 7 19.7835 7 20.75C7 21.7165 7.7835 22.5 8.75 22.5Z' 
                fill={props.fill || 'white'}/>
            <path d='M5.34 3.94L5.14 6.39C5.1 6.86 5.47 7.25 5.94 7.25H21.25C21.67 7.25 22.02 6.93 22.05 6.51C22.18 4.74 20.83 3.3 19.06 3.3H6.77C6.67 2.86 6.47 2.44 6.16 2.09C5.66 1.56 4.96 1.25 4.24 1.25H2.5C2.09 1.25 1.75 1.59 1.75 2C1.75 2.41 2.09 2.75 2.5 2.75H4.24C4.55 2.75 4.84 2.88 5.05 3.1C5.26 3.33 5.36 3.63 5.34 3.94Z' 
                fill={props.fill || 'white'}/>
            <path d='M21.0101 8.75H5.67005C5.25005 8.75 4.91005 9.07 4.87005 9.48L4.51005 13.83C4.37005 15.54 5.71005 17 7.42005 17H18.5401C20.0401 17 21.3601 15.77 21.4701 14.27L21.8001 9.6C21.8401 9.14 21.4801 8.75 21.0101 8.75Z' 
                fill={props.fill || 'white'}/>
        </svg>
    );
}

export default CartIcon;

CartIcon.propTypes = {
    fill: propTypes.oneOfType([
        propTypes.string,
        propTypes.bool,
    ]),
};
