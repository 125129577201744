import React from 'react';
import propTypes from 'prop-types';

const UtensilsIcon = ({ color = '', size, ...props }) => {
    return (
        <svg
            width={size || '56'}
            height={size || '56'}
            viewBox='0 0 56 56'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M47.2186 4.78988C46.9376 4.62682 46.591 4.62564 46.3089 4.78678C42.0741 7.20669 39.4434 11.7399 39.4434 16.6172V33.0334C39.4434 33.5368 39.8514 33.9448 40.3548 33.9448H42.1889V48.5914C42.1889 50.1032 43.4189 51.3332 44.9307 51.3332C46.4425 51.3332 47.6725 50.1032 47.6725 48.5914V33.0335V5.5782C47.6726 5.25326 47.4997 4.95294 47.2186 4.78988ZM41.2664 16.6173C41.2664 12.9441 42.989 9.49617 45.8497 7.2807V32.1219H41.2664V16.6173ZM44.9308 49.5103C44.4241 49.5103 44.0119 49.0981 44.0119 48.5914V33.9449H45.8497V48.5915C45.8497 49.0981 45.4375 49.5103 44.9308 49.5103Z'
                fill={color || '#D6AD60'}/>
            <path
                d='M16.5605 4.66675C16.0571 4.66675 15.6491 5.07481 15.6491 5.5782V15.6489H13.8112V5.5782C13.8112 5.07481 13.4032 4.66675 12.8998 4.66675C12.3964 4.66675 11.9883 5.07481 11.9883 5.5782V15.6489H10.1505V5.5782C10.1505 5.07481 9.74249 4.66675 9.23909 4.66675C8.73569 4.66675 8.32764 5.07481 8.32764 5.5782V18.3906C8.32764 20.5996 9.90245 22.4477 11.9883 22.8713V26.7873C10.9233 27.1639 10.158 28.1804 10.158 29.3727V48.5914C10.158 50.1032 11.3879 51.3332 12.8998 51.3332C14.4221 51.3332 15.6416 50.0964 15.6416 48.5478V29.3727C15.6416 28.1804 14.8763 27.1639 13.8112 26.7873V22.8713C15.8972 22.4477 17.472 20.5996 17.472 18.3906V5.5782C17.472 5.07481 17.0639 4.66675 16.5605 4.66675ZM13.8187 48.5478C13.8187 49.0611 13.4217 49.4926 12.9337 49.5098C12.4114 49.5288 11.9809 49.1094 11.9809 48.5915V29.3727C11.9809 28.866 12.3931 28.4538 12.8998 28.4538C13.4064 28.4538 13.8187 28.866 13.8187 29.3727V48.5478ZM12.8998 21.1399C11.3838 21.1399 10.1505 19.9066 10.1505 18.3906V17.4718H15.6491V18.3907C15.6491 19.9066 14.4157 21.1399 12.8998 21.1399Z'
                fill={color || '#D6AD60'}/>
            <path
                d='M28.4577 4.66675C26.6764 4.66675 25.0283 5.66762 23.8166 7.48506C22.6807 9.18884 22.0552 11.4368 22.0552 13.8147C22.0552 16.2064 22.6819 18.0706 23.9181 19.3557C24.8477 20.322 26.0901 20.908 27.5462 21.0789V26.7874C26.4812 27.1639 25.7159 28.1805 25.7159 29.3728V48.5915C25.7159 50.1033 26.9459 51.3333 28.4577 51.3333C29.9695 51.3333 31.1995 50.1033 31.1995 48.5915V29.3727C31.1995 28.1804 30.4342 27.1639 29.3691 26.7873V21.0788C30.8253 20.908 32.0677 20.3219 32.9973 19.3556C34.2334 18.0706 34.8602 16.2064 34.8602 13.8147C34.8602 11.4367 34.2347 9.18875 33.0988 7.48497C31.8872 5.66762 30.2389 4.66675 28.4577 4.66675ZM29.3766 48.5914C29.3766 49.0981 28.9644 49.5103 28.4577 49.5103C27.951 49.5103 27.5388 49.0981 27.5388 48.5914V29.3727C27.5388 28.866 27.951 28.4538 28.4577 28.4538C28.9644 28.4538 29.3766 28.866 29.3766 29.3727V48.5914ZM28.4577 19.3095C25.5045 19.3095 23.8781 17.3581 23.8781 13.8147C23.8781 11.7927 24.3949 9.90387 25.3333 8.49622C27.1188 5.81801 29.8005 5.82402 31.5821 8.49622C32.5205 9.90387 33.0373 11.7927 33.0373 13.8147C33.0374 17.3581 31.4109 19.3095 28.4577 19.3095Z'
                fill={color || '#D6AD60'}/>
        </svg>
    );
};

export default UtensilsIcon;

UtensilsIcon.propTypes = {
    color: propTypes.string,
    size: propTypes.any
};
