import React from 'react';
import PropTypes from 'prop-types';

function ChatBox({ message, messageReply, styleWrapperChat, handleReply, isCurrentUser, senderName, idx, log, dateString, sending=false }) {
    return (
        <>
            <div className={`flex relative ${isCurrentUser ? 'bg-colors-gold400' : 'bg-colors-gray50'} rounded`}>
                <div className={`w-0 h-0 border-b-[8px] border-l-[4px] border-l-transparent border-r-[4px] border-r-transparent bg-transparent  opacity-60 absolute mt-[8px] ${styleWrapperChat}`} />
                <div className='w-full'>
                    { messageReply?.comment && messageReply?.dir_image? (
                        <div className='p-[8px] pb-0'>
                            <div className={`p-[8px]`} style={{borderRadius: '4px', backgroundColor: isCurrentUser? '#C09A52' : '#D4D7E2 '}}>
                                <p className={`__text-title1 font-bold __ff-lato ${isCurrentUser ? 'text-colors-white' : 'text-colors-blue400'}`}>{messageReply?.sender.includes('/') ? messageReply.sender.split('/')[0] : messageReply.sender}</p>

                                <div>
                                    <img
                                        src={process.env.REACT_APP_API_URL + messageReply?.dir_image}
                                        alt={"Uploaded"}
                                        style={{
                                            paddingTop: '4px',
                                            paddingBottom: '4px',
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                        }}/>
                                    <p className={`${isCurrentUser ? 'text-colors-white' : 'text-colors-blue400'}`}>{messageReply?.comment}</p>
                                </div>
                            </div>
                        </div>
                    )
                        :
                        messageReply?.comment? (
                            <div className='p-[8px] pb-0'>
                                <div className={`p-[8px]`} style={{borderRadius: '4px', backgroundColor: isCurrentUser? '#C09A52' : '#D4D7E2 '}}>
                                    <p className={`__text-title1 font-bold __ff-lato ${isCurrentUser ? 'text-colors-white' : 'text-colors-blue400'}`}>{messageReply?.sender.includes('/') ? messageReply.sender.split('/')[0] : messageReply.sender}</p>
                                    <p className={`${isCurrentUser ? 'text-colors-white' : 'text-colors-blue400'}`}>{messageReply?.comment}</p>
                                </div>
                            </div>)
                            :
                            messageReply?.dir_image ? (
                                <div className='p-[8px] pb-0'>
                                    <div className={`p-[8px]`} style={{borderRadius: '4px', backgroundColor: isCurrentUser? '#C09A52' : '#D4D7E2 '}}>
                                        <p className={`__text-title1 font-bold __ff-lato ${isCurrentUser ? 'text-colors-white' : 'text-colors-blue400'}`}>{messageReply?.sender.includes('/') ? messageReply.sender.split('/')[0] : messageReply.sender}</p>
                                        <img
                                            src={process.env.REACT_APP_API_URL + messageReply?.dir_image}
                                            alt={"Uploaded"}
                                            style={{
                                                paddingTop: '4px',
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                            }}/>
                                    </div>
                                </div>
                            )
                                : null
                    }

                    <div className='flex flex-col p-[8px] gap-[12px] w-full'>
                        {message.dir_image && (
                            <img
                                src={sending ? URL.createObjectURL(message.dir_image) : process.env.REACT_APP_API_URL + message.dir_image}
                                alt={"Uploaded"}
                                style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'contain',
                                }}/>
                        )}
                        <p className={`${isCurrentUser ? 'text-colors-white' : 'text-colors-blue400'}`}>{message.comment}</p>
                        <div className='flex justify-between'>
                            {isCurrentUser ? (
                                <div> </div>
                            ) : (
                                <p className='__text-caption __ff-lato text-colors-gray200'>{senderName} &nbsp; &nbsp; &nbsp;</p>
                            )}
                            <p className={`__text-caption __ff-lato ${isCurrentUser ? 'text-colors-white' : 'text-colors-gray200'}`}>
                                {dateString}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            {!sending && (
                <div className={`flex ${isCurrentUser ? 'justify-end' : 'justify-start'} ${idx === log.message.length - 1 ? 'pt-2' : 'pb-4 pt-2'}`}>
                    <div className='hover:underline __text-caption __ff-lato text-colors-gray200' style={{ cursor: 'pointer' }} onClick={() => handleReply(message)}>
                        <p>Reply</p>
                    </div>
                </div>
            )}
        </>
    );
}

export default ChatBox;

ChatBox.propTypes = {
    message: PropTypes.object,
    messageReply: PropTypes.any,
    handleReply: PropTypes.func,
    isCurrentUser: PropTypes.bool,
    styleWrapperChat: PropTypes.string,
    senderName: PropTypes.string,
    idx: PropTypes.number,
    log: PropTypes.object,
    dateString: PropTypes.string,
    sending: PropTypes.bool
};
