import React from 'react';
import propTypes from 'prop-types';

const MapPinIcon = ({ fill, color, size, ...props }) => {
    return (
        <svg
            width={size || '24'}
            height={size || '24'}
            viewBox='0 0 24 24'
            fill={fill || 'none'}
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M11.9996 14.17C9.86964 14.17 8.12964 12.44 8.12964 10.3C8.12964 8.16 9.86964 6.44 11.9996 6.44C14.1296 6.44 15.8696 8.17 15.8696 10.31C15.8696 12.45 14.1296 14.17 11.9996 14.17ZM11.9996 7.94C10.6996 7.94 9.62964 9 9.62964 10.31C9.62964 11.62 10.6896 12.68 11.9996 12.68C13.3096 12.68 14.3696 11.62 14.3696 10.31C14.3696 9 13.2996 7.94 11.9996 7.94Z'
                fill={color || '#D6AD60'}/>
            <path
                d='M11.9997 22.76C10.5197 22.76 9.02969 22.2 7.86969 21.09C4.91969 18.25 1.65969 13.72 2.88969 8.33C3.99969 3.44 8.26969 1.25 11.9997 1.25H12.0097C15.7397 1.25 20.0097 3.44 21.1197 8.34C22.3397 13.73 19.0797 18.25 16.1297 21.09C14.9697 22.2 13.4797 22.76 11.9997 22.76ZM11.9997 2.75C9.08969 2.75 5.34969 4.3 4.35969 8.66C3.27969 13.37 6.23969 17.43 8.91969 20C10.6497 21.67 13.3597 21.67 15.0897 20C17.7597 17.43 20.7197 13.37 19.6597 8.66C18.6597 4.3 14.9097 2.75 11.9997 2.75Z'
                fill={color || '#D6AD60'}/>
        </svg>
    );
};

export default MapPinIcon;

MapPinIcon.propTypes = {
    fill: propTypes.string,
    color: propTypes.string,
    size: propTypes.any,
};
