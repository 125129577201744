import { API_URL } from "~/config/api";

export async function loadVillaList(filter) {

    try {
        const response = await API_URL.post('web/villa/list', filter);

        return response;
    } catch (err) {
        return false;
    }
}

export async function loadVillaDetail(Id) {
    try {
        const response = await API_URL.post('web/villa/detail', { id: Id });

        return response;
    } catch (err) {
        return false;
    }
}

export async function loadService() {
    try {
        const response = await API_URL.post('web/experience/list_code');

        return response;
    } catch (err) {
        return false;
    }
}

export async function loadProvince() {
    try {
        const response = await fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/provinces.json`)
            .then(response => response.json());
            
        return response;
    } catch (err) {
        return false;
    }
}

export async function loadRegencies(ProvinceId) {
    try {
        const response = await fetch(`https://www.emsifa.com/api-wilayah-indonesia/api/regencies/${ProvinceId}.json`)
            .then(response => response.json());
            
        return response;
    } catch (err) {
        return false;
    }
}
