import { API_URL } from "~/config/api";

export async function loadHomepage() {
    try {
        const response = await API_URL.post('web/home/list');

        return response;
    } catch (err) {
        return false;
    }
}

export async function loadGuestData(formData) {
    try {
        const response = await API_URL.post('web/login/login_hint', formData);

        return response;
    } catch (err) {
        return false;
    }
}

export async function loginGuest(formData) {
    try {
        const response = await API_URL.post('web/login', formData);

        return response;
    } catch (err) {
        return false;
    }
}

export async function sendChat(formData) {
    try {
        const response = await API_URL.post('web/guest_chat/create', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        return response;
    } catch (err) {
        return false;
    }
}