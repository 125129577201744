import React from 'react';
import propTypes from 'prop-types';

const WifiIcon = ({size, ...props}) => {
    return (
        <svg
            width={size || '24'}
            height={size || '24'}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M8.39846 14.9316C7.97786 15.3522 7.97786 16.0342 8.39846 16.4546C8.81921 16.8752 9.50103 16.8752 9.92164 16.4546C11.0676 15.3087 12.9325 15.3087 14.0784 16.4546C14.2888 16.6649 14.5645 16.7701 14.8401 16.7701C15.1157 16.7701 15.3914 16.665 15.6018 16.4546C16.0224 16.034 16.0224 15.3521 15.6018 14.9316C13.6158 12.9456 10.3845 12.9456 8.39846 14.9316Z'
                fill='#D6AD60'/>
            <path
                d='M5.24124 11.7748C4.82063 12.1954 4.82063 12.8774 5.24124 13.2979C5.66184 13.7183 6.34366 13.7186 6.76441 13.2979C9.65107 10.4112 14.3481 10.4112 17.2346 13.2979C17.445 13.5081 17.7207 13.6134 17.9963 13.6134C18.2719 13.6134 18.5476 13.5082 18.7579 13.2979C19.1786 12.8773 19.1786 12.1953 18.7579 11.7748C15.0314 8.0484 8.96782 8.0484 5.24124 11.7748Z'
                fill='#D6AD60'/>
            <path
                d='M22.2814 8.25188C16.6122 2.58267 7.38785 2.58267 1.71878 8.25188C1.29817 8.67249 1.29817 9.35445 1.71878 9.77491C2.13953 10.1955 2.82134 10.1955 3.24195 9.77491C5.58121 7.43551 8.6916 6.14727 12 6.14727C15.3084 6.14727 18.4188 7.43565 20.7581 9.77491C20.9685 9.98514 21.2442 10.0904 21.5197 10.0904C21.7953 10.0904 22.071 9.98529 22.2814 9.77491C22.702 9.35445 22.702 8.67249 22.2814 8.25188Z'
                fill='#D6AD60'/>
            <path
                d='M12.0003 20C12.6531 20 13.1823 19.4708 13.1823 18.818C13.1823 18.1652 12.6531 17.636 12.0003 17.636C11.3475 17.636 10.8184 18.1652 10.8184 18.818C10.8184 19.4708 11.3475 20 12.0003 20Z'
                fill='#D6AD60'/>
        </svg>
    );
};

export default WifiIcon;

WifiIcon.propTypes = {
    size: propTypes.any
};