import React from 'react';

const MapIcon = (props) => {
    return (
        <svg
            width='48'
            height='48'
            viewBox='0 0 48 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M31.302 42.8194C30.442 42.8194 29.582 42.6394 28.882 42.2994L18.382 37.0394C17.782 36.7394 16.602 36.7594 16.022 37.0994L11.302 39.7994C9.26203 40.9594 7.16203 41.1194 5.58203 40.1794C3.98203 39.2594 3.08203 37.3794 3.08203 35.0194V15.5793C3.08203 13.7593 4.28203 11.6993 5.86203 10.7993L14.522 5.83933C15.982 4.99933 18.202 4.93933 19.702 5.69933L30.202 10.9593C30.802 11.2593 31.962 11.2193 32.562 10.8993L37.262 8.21934C39.302 7.05934 41.402 6.89933 42.982 7.83933C44.582 8.75934 45.482 10.6393 45.482 12.9993V32.4594C45.482 34.2794 44.282 36.3394 42.702 37.2394L34.042 42.1994C33.282 42.5994 32.282 42.8194 31.302 42.8194ZM17.282 33.8394C18.142 33.8394 19.002 34.0194 19.702 34.3594L30.202 39.6194C30.802 39.9194 31.962 39.8794 32.562 39.5594L41.222 34.5994C41.862 34.2394 42.482 33.1594 42.482 32.4394V12.9793C42.482 11.7193 42.122 10.7793 41.462 10.4193C40.822 10.0593 39.822 10.1993 38.742 10.8193L34.042 13.4993C32.582 14.3393 30.362 14.3993 28.862 13.6393L18.362 8.37933C17.762 8.07933 16.602 8.11933 16.002 8.43933L7.34203 13.3993C6.70203 13.7593 6.08203 14.8393 6.08203 15.5793V35.0394C6.08203 36.2994 6.44203 37.2394 7.08203 37.5994C7.72203 37.9794 8.72203 37.8194 9.82203 37.1994L14.522 34.5194C15.302 34.0594 16.302 33.8394 17.282 33.8394Z'
                fill='#D6AD60'/>
            <path
                d='M17.1221 35.4995C16.3021 35.4995 15.6221 34.8195 15.6221 33.9995V7.99939C15.6221 7.17939 16.3021 6.49939 17.1221 6.49939C17.9421 6.49939 18.6221 7.17939 18.6221 7.99939V33.9995C18.6221 34.8195 17.9421 35.4995 17.1221 35.4995Z'
                fill='#D6AD60'/>
            <path
                d='M31.462 41.4994C30.642 41.4994 29.962 40.8194 29.962 39.9994V13.2394C29.962 12.4194 30.642 11.7394 31.462 11.7394C32.282 11.7394 32.962 12.4194 32.962 13.2394V39.9994C32.962 40.8194 32.282 41.4994 31.462 41.4994Z'
                fill='#D6AD60'/>
        </svg>
    );
};

export default MapIcon;
