import React from 'react';

function SortIcon() {
    return (
        <svg
            width={20}
            height={20}
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M17.5 6.45837H2.5C2.15833 6.45837 1.875 6.17504 1.875 5.83337C1.875 5.49171 2.15833 5.20837 2.5 5.20837H17.5C17.8417 5.20837 18.125 5.49171 18.125 5.83337C18.125 6.17504 17.8417 6.45837 17.5 6.45837Z'
                fill='#24336B'/>
            <path
                d='M15 10.625H5C4.65833 10.625 4.375 10.3417 4.375 10C4.375 9.65833 4.65833 9.375 5 9.375H15C15.3417 9.375 15.625 9.65833 15.625 10C15.625 10.3417 15.3417 10.625 15 10.625Z'
                fill='#24336B'/>
            <path
                d='M11.6663 14.7917H8.33301C7.99134 14.7917 7.70801 14.5084 7.70801 14.1667C7.70801 13.825 7.99134 13.5417 8.33301 13.5417H11.6663C12.008 13.5417 12.2913 13.825 12.2913 14.1667C12.2913 14.5084 12.008 14.7917 11.6663 14.7917Z'
                fill='#24336B'/>
        </svg>
    );
}

export default SortIcon;
