import React from 'react';
import propTypes from 'prop-types';

const PlusIcon = ({ stroke, ...props }) => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M5 10H15'
                stroke={stroke ? stroke : '#D6AD60'}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'/>
            <path
                d='M10 15V5'
                stroke={stroke ? stroke : '#D6AD60'}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'/>
        </svg>
    );
};

export default PlusIcon;

PlusIcon.propTypes = {
    stroke: propTypes.string,
};
