import React from 'react';
import propTypes from 'prop-types';
import { BedIcon, ShowerIcon } from '~/assets/icons';

function Card({ ImageSource, Title, Subtitle, Facilities = [], Price, onClick }) {
    return (
        <div className='flex flex-col' onClick={onClick}>
            <img src={ImageSource} style={{ height: 280, width: 'auto', objectFit: 'cover' }} />

            <div className='flex flex-col gap-[16px]'>
                <div className='mt-[20px]'>
                    {Subtitle}
                </div>

                <h3 className='__text-title-1 __ff-neuton tracking-wider'>{Title}</h3>
                <div className='flex flex-wrap gap-[12px]'>
                    {
                        Facilities.map((val, index) => (
                            <div className='flex gap-[7px] flex-1 text-colors-gray200' key={index}>
                                {index === 0 ? <BedIcon /> : <ShowerIcon />}
                                {val}
                            </div>
                        ))
                    }
                </div>
                <div className='flex __text-body-1 __ff-lato'>
                    <span className=''>IDR {Price}</span>
                    <span className='text-colors-gray200'>/nights</span>
                </div>
            </div>
        </div>
    );
}

export default Card;

Card.propTypes = {
    ImageSource: propTypes.string,
    Title: propTypes.string,
    Subtitle: propTypes.element,
    Facilities: propTypes.array,
    Price: propTypes.string,
    onClick: propTypes.func
};