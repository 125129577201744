import FeaturedVilla1 from '~/assets/image/villa1.png';
import ArticleImage1 from '~/assets/image/article1.png';

import {
    AcIcon,
    BathubIcon,
    BedIcon,
    BlanketIcon,
    CrownIcon,
    DumbelsIcon,
    ElevatorIcon,
    FbIcon,
    HangerIcon,
    IgIcon,
    JacuzziIcon,
    MapIcon,
    RefrigeratorIcon,
    RulerIcon,
    SaunaIcon,
    ShowerIcon,
    SmartTvIcon,
    SpaIcon,
    SpeakerIcon,
    SwimmingPoolIcon,
    ThumbIcon,
    ToursIcon,
    UserIcon,
    UtensilsIcon,
    WaterHeaterIcon,
    WifiIcon,
    XIcon,
    YtIcon,
} from '~/assets/icons';
import { ROUTES } from './routes/Routes';
import VillaImg from '~/assets/image/img.png';
import DiningRoom from '~/assets/image/dining-room.png';
import LivingRoom from '~/assets/image/living-room.png';
import BedRoom from '~/assets/image/bedroom.png';
import BathRoom from '~/assets/image/bathroom.png';

import Sauna1 from '~/assets/image/room-tours/sauna1.jpg';
import Sauna2 from '~/assets/image/room-tours/sauna2.jpg';
import Gym1 from '~/assets/image/room-tours/gym1.jpg';
import Gym2 from '~/assets/image/room-tours/gym2.jpg';
import Pool1 from '~/assets/image/room-tours/pool1.jpg';
import Pool2 from '~/assets/image/room-tours/pool2.jpg';
import MiniMap from '~/assets/image/mini-map-room.png';

import Why1 from '~/assets/image/Why/Img1.png';
import Why2 from '~/assets/image/Why/Img2.png';
import Why3 from '~/assets/image/Why/Img3.png';
import Insta1 from '~/assets/image/Insta/Img1.png';
import Insta2 from '~/assets/image/Insta/Img2.png';
import Insta3 from '~/assets/image/Insta/Img3.png';
import Insta4 from '~/assets/image/Insta/Img4.png';
import Insta5 from '~/assets/image/Insta/Img5.png';
import Insta6 from '~/assets/image/Insta/Img6.png';
import Insta7 from '~/assets/image/Insta/Img7.png';
import DetailExp1 from '~/assets/image/DetailExp/Img1.png';
import DetailExp2 from '~/assets/image/DetailExp/Img2.png';
import DetailExp3 from '~/assets/image/DetailExp/Img3.png';
import DetailExp4 from '~/assets/image/DetailExp/Img4.png';
import DetailExp5 from '~/assets/image/DetailExp/Img5.png';
import DetailExp6 from '~/assets/image/DetailExp/Img6.png';

import Dashboard1 from '~/assets/image/img.png';

export const baseSize = 1536;
export const ASSETS_LINK = 'https://staging.izzibook.co.id/backend/api-nuswapada/';

export const navMenu = [
    { label: 'About Us', route: ROUTES.About },
    { label: 'Villa', route: ROUTES.SearchResult },
    { label: 'Experiences', route: ROUTES.Experiences },
    { label: 'Articles', route: ROUTES.Articles },
    { label: 'Contact Us', route: ROUTES.Contact },
];

export const socialMediaIcon = [
    {
        icon: FbIcon,
    },
    {
        icon: IgIcon,
    },
    {
        icon: XIcon,
    },
    {
        icon: YtIcon,
    },
];

export const footerList = [
    {
        Collection: ['Villa', 'Services'],
        Nuswapada: ['About Us', 'Blog', 'Contact Us'],
        Others: ['Terms & Conditions', 'Privacy Policy'],
    },
];

export const villaBenefit = [
    {
        icon: CrownIcon,
        title: 'Tailored Luxury Experiences',
        content:
            'Indulge in personalized concierge services, bespoke villa amenities, exclusive dining experiences, luxurious in-villa spa treatments, and tailor-made recreational and cultural activities.',
    },
    {
        icon: MapIcon,
        title: 'Exclusive Locations',
        content:
            'Discover our collection of exquisite villas nestled in exclusive locations around the world. Each villa offers a unique blend of luxury and serenity, providing an unparalleled retreat for discerning travelers.',
    },
    {
        icon: ThumbIcon,
        title: 'Service Excellence',
        content:
            'At our villa, service excellence is at the heart of everything we do. Our dedicated team is committed to exceeding your expectations, ensuring that every aspect of your stay is nothing short of exceptional.',
    },
];

export const ListVilla = [
    {
        Location: 'Denpasar, Bali',
        Name: 'Sunset Retreat Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 3,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Emerald Haven Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 4,
            Bathrooms: 3
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Serenity Escape Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 2,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Sunset Retreat Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 3,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Emerald Haven Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 4,
            Bathrooms: 3
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Serenity Escape Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 2,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Sunset Retreat Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 3,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Emerald Haven Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 4,
            Bathrooms: 3
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Serenity Escape Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 2,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Sunset Retreat Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 3,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Emerald Haven Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 4,
            Bathrooms: 3
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Serenity Escape Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 2,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Serenity Escape Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 2,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Sunset Retreat Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 3,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Emerald Haven Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 4,
            Bathrooms: 3
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Serenity Escape Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 2,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Sunset Retreat Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 3,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Emerald Haven Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 4,
            Bathrooms: 3
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Serenity Escape Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 2,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Sunset Retreat Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 3,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Emerald Haven Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 4,
            Bathrooms: 3
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Serenity Escape Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 2,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Sunset Retreat Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 3,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Emerald Haven Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 4,
            Bathrooms: 3
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Serenity Escape Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 2,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Serenity Escape Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 2,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Sunset Retreat Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 3,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Emerald Haven Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 4,
            Bathrooms: 3
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Serenity Escape Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 2,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Sunset Retreat Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 3,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Emerald Haven Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 4,
            Bathrooms: 3
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Serenity Escape Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 2,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Sunset Retreat Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 3,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Emerald Haven Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 4,
            Bathrooms: 3
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Serenity Escape Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 2,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Sunset Retreat Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 3,
            Bathrooms: 2
        }
    },
    {
        Location: 'Denpasar, Bali',
        Name: 'Emerald Haven Villa',
        Image: VillaImg,
        Facilities: {
            Bedrooms: 4,
            Bathrooms: 3
        }
    }
];

export const featuredVilla = {
    img: FeaturedVilla1,
    location: 'Nusa Penida, Bali',
    name: 'Maria Villa',
    bedroom: '4 Bedrooms',
    bathroom: '5 Bathrooms',
    price: 'IDR 8.500.000',
    unit: 'nights',
    room: 'spa',
};

export const feedbackList = [
    {
        name: 'John Doe',
        location: 'Luxembourg',
        content:
            'Had an amazing time at Nuswapada! The villa was exceptional, with top-notch facilities. It was spacious, always spotless, and well-maintained.',
    },
    {
        name: 'John Depp',
        location: 'Luxembourg',
        content:
            'Had an amazing time at Nuswapada! The villa was exceptional, with top-notch facilities. It was spacious, always spotless, and well-maintained.',
    },
    {
        name: 'Kim Hee-Chul',
        location: 'Luxembourg',
        content:
            'Had an amazing time at Nuswapada! The villa was exceptional, with top-notch facilities. It was spacious, always spotless, and well-maintained.',
    },
];

export const articleList = [
    {
        img: ArticleImage1,
        label: 'tips',
        title: '4 Tips to enjoy your travel. You will be surprised with tips number 3',
        date: '12 February 2024',
        content: `Traveling is not just about reaching your destination; it's about enjoying every moment of the journey. Whether you're embarking on a weekend getaway or a month-long. Whether you're embarking on a weekend getaway or a month-long expedition, savoring the sights, sounds, and experiences along the way adds depth and richness to your travel memories.`,
        read: '10 mins read',
    },
    {
        img: ArticleImage1,
        label: 'Destination Guides',
        title: 'Bali explore : Beauty of Agung Mountain',
        date: '8 February 2024',
        content: `Bali, known for its stunning beaches and vibrant culture, is also home to the majestic Mount Agung. Towering over the island at 3,031 meters (9,944 feet) above sea level. Mount Agung is an active volcano and is considered sacred by the Balinese people, playing a significant role in their spiritual beliefs and ceremonies.`,
        read: '5 mins read',
    },
    {
        img: ArticleImage1,
        label: 'News',
        title: 'Komodo Island has been add to 7 World Wonder',
        date: '1 February 2024',
        content: `Komodo Island, home to the famous Komodo dragons and breathtaking natural landscapes, has recently been added to the prestigious list of the Seven Wonders of Nature, joining other iconic destinations such as the Amazon Rainforest, Halong Bay, and the Great Barrier Reef.`,
        read: '7 mins read',
    },
];

export const citiesList = ['Jakarta', 'Depok', 'Bogor', 'Bandung'];

export const provinceList = ['DKI Jakarta', 'West Java', 'Sulawesi', 'Sumatra'];

export const lists = [
    {
        day: '25-03-2024',
        isDisabled: '0',
        price: 'Rp20.000',
    },
    {
        day: '30-03-2024',
        isDisabled: '1',
        price: '',
    },
    {
        day: '27-03-2024',
        isDisabled: '0',
        price: 'Rp20.000',
    },
];
export const ChooseUs = [
    {
        image: Why1,
        content: '500 Locations',
    },
    {
        image: Why2,
        content: '10.000 Happy Guests',
    },
    {
        image: Why3,
        content: '30 Services Availables',
    },
];

export const articleFeature = ['news', 'destination guides', 'tips'];

export const articleNews = [
    {
        label: 'Nuswapada Opens Luxury Bali Resort'
    },
    {
        label: 'Nuswapada Launches Eco Villas in Lombok'
    },
    {
        label: 'Exclusive Caribbean Retreats by Nuswapada'
    },
    {
        label: `Tuscany's Ancient Villas: A Nuswapada Revival`
    },
    {
        label: 'Nuswapada Boosts the Local Economy'
    },
    {
        label: `Philippines' Pride: Nuswapada's Villas`
    }
];
export const listCartArr = [
    {
        itemName: 'Laundry',
        price: '50000',
        priceByItem: 'item',
        qty: 3,
        date: '2024-03-01 08:00:00',
        notes: 'Please give extra parfume for my laundry'
    },
    {
        itemName: 'Spa',
        price: '3500000',
        priceByItem: 'hour',
        qty: 1,
        date: '2024-03-02 07:30:00',
        notes: ''
    }
];

export const featuredOfVilla = [
    {
        text: '56m2',
        icon: RulerIcon,
    },
    {
        text: '4',
        icon: BedIcon
    },
    {
        text: '5',
        icon: ShowerIcon
    },
    {
        text: '4-8',
        icon: UserIcon
    }
];

export const amenitiesVilla = [
    {
        label: 'Wi-Fi',
        icon: WifiIcon
    },
    {
        label: 'Console Game',
        icon: BedIcon
    },
    {
        label: 'Air Conditioning',
        icon: AcIcon
    },
    {
        label: 'Bathub',
        icon: BathubIcon
    },
    {
        label: 'Linens',
        icon: BlanketIcon
    },
    {
        label: 'Hanger',
        icon: HangerIcon
    },
    {
        label: 'Hot Water',
        icon: WaterHeaterIcon
    },
    {
        label: 'Elevator',
        icon: ElevatorIcon
    },
    {
        label: 'Smart TV',
        icon: SmartTvIcon
    },
    {
        label: 'Refrigerator',
        icon: RefrigeratorIcon
    },
    {
        label: 'Sound System',
        icon: SpeakerIcon
    },
    {
        label: 'Jacuzzi',
        icon: JacuzziIcon
    },
];

export const facilitiesVilla = [
    {
        label: 'Gym',
        icon: DumbelsIcon,
        images: [Gym1, Gym2]
    },
    {
        label: 'Sauna',
        icon: SaunaIcon,
        images: [Sauna1, Sauna2, Pool2, Gym2]
    },
    {
        label: 'Pool',
        icon: SwimmingPoolIcon,
        images: [Pool1, Pool2]
    }
];

export const experienceAvailable = [
    {
        label: 'Spa',
        content: 'Rest, rebalance or renew with local healing therapies that have been created with beauty and wellness at the heart.',
        icon: SpaIcon
    },
    {
        label: 'Private Dinner',
        content: 'Rest, rebalance or renew with local healing therapies that have been created with beauty and wellness at the heart.',
        icon: UtensilsIcon
    },
    {
        label: 'Local Tours',
        content: 'Rest, rebalance or renew with local healing therapies that have been created with beauty and wellness at the heart.',
        icon: ToursIcon
    }
];

export const distanceVilla = [
    {
        name: 'Garuda Kencana',
        location: '2.0 km'
    },
    {
        name: 'Lodge Maribaya',
        location: '(4.1 km)'
    },
    {
        name: 'Ubud',
        location: '(5.0 km)'
    },
    {
        name: 'Kuta Beach',
        location: '(1.1 km)'
    },
    {
        name: 'Pandawa Beach',
        location: '(2.3 km)'
    }
];

export const reviewsVilla = [
    {   
        name: 'John Doe',
        location: 'Luxembourg',
        reason: ' Had an amazing time at Nuswapada! The villa was exceptional, with top-notch facilities. It was spacious, always spotless, and well-maintained.',
        date: '1 February 2023'
    },
    {   
        name: 'John Depp',
        location: 'Luxembourg',
        reason: 'The villa was superb, offering great amenities. It was spacious, impeccably clean, and well-maintained.',
        date: '13 February 2023'
    },
    {   
        name: 'Kim Hee-Chul',
        location: 'Luxembourg',
        reason: 'Our stay at Nuswapada was pure luxury! The villa was simply stunning, equipped with outstanding facilities.',
        date: '12 February 2023'
    },
    {   
        name: 'John Doe',
        location: 'Luxembourg',
        reason: ' Had an amazing time at Nuswapada! The villa was exceptional, with top-notch facilities. It was spacious, always spotless, and well-maintained.',
        date: '1 February 2023'
    },
    
];

export const include = [
    {
        title: 'Comprehensive Cleaning',
        desc: 'Wash, dry, and iron services for a range of clothing items.'
    },
    {
        title: 'Quality Care',
        desc: 'Use of premium detergents and fabric softeners to ensure the best care for your garments.'
    },
    {
        title: 'Prompt Service',
        desc: 'Timely pick-up and delivery of your laundry within the villa premises.'
    },
    {
        title: 'Ambience and Decor',
        desc: 'Elegant table settings, subtle lighting, and tasteful decor to set the perfect mood.'
    },
    {
        title: 'Flexible Loads',
        desc: 'Accommodating various load sizes to suit your needs.'
    },
];

export const faq = [
    {
        title: 'How is the cost of the laundry service determined?',
        desc: 'The cost is based on per load basis, estimated at IDR 75,000 per load.'
    },
    {
        title: `What is considered a 'load' of laundry?`,
        desc: 'A load typically includes a mix of shirts, pants, and similar items, weighing up to a certain limit.'
    },
    {
        title: 'Can I have delicate items or special fabrics cleaned?',
        desc: 'Yes, please specify any special care instructions for delicate items when you request the service.'
    },
    {
        title: 'How long does the laundry service take?',
        desc: 'Standard turnaround is usually within 24 hours, but we can accommodate urgent requests when possible.'
    },
];

export const AmenitiesVilla = [
    {
        icon: WifiIcon,
        label: 'Wi-Fi'
    },
    {
        icon: BedIcon,
        label: 'Console Game'
    },
    {
        icon: AcIcon,
        label: 'Ait Conditioning'
    },
    {
        icon: BathubIcon,
        label: 'Bathtub'
    },
    {
        icon: BlanketIcon,
        label: 'Linens'
    },
    {
        icon: HangerIcon,
        label: 'Hanger'
    },
    {
        icon: WaterHeaterIcon,
        label: 'Hot Water'
    },
    {
        icon: ElevatorIcon,
        label: 'Elevator'
    }

];

export const roomsMapping = [
    { name: 'Dining Room', image: DiningRoom },
    { name: 'Living Room', image: LivingRoom },
    { name: 'Bedroom', image: BedRoom },
    { name: 'Bathroom', image: BathRoom },
];
export const imageInsta = [
    {
        image: Insta1,
    },
    {
        image: Insta2,
    },
    {
        image: Insta3,
    },
    {
        image: Insta4,
    },
    {
        image: Insta5,
    },
    {
        image: Insta6,
    },
    {
        image: Insta7,
    },
] ;

export const imageDetailExp = [
    {
        image: DetailExp1,
    },
    {
        image: DetailExp2,
    },
    {
        image: DetailExp3,
    },
    {
        image: DetailExp4,
    },
    {
        image: DetailExp5,
    },
    {
        image: DetailExp6,
    },
];

export const HotelLocationDummy = {
    "type": "FeatureCollection",
    "features": [{
        "type": "Feature",
        "geometry": {
            "type": "Point",
            "coordinates": [106.640778, -6.270278]
        },
        "properties": {
            "address": "Rp1.350.000",
            "city": "Bali"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "Point",
            "coordinates": [106.638020, -6.263420]
        },
        "properties": {
            "address": "Rp3.500.000",
            "city": "Bali"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "Point",
            "coordinates": [106.633861, -6.290278]
        },
        "properties": {
            "address": "Rp950.000",
            "city": "Bali"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "Point",
            "coordinates": [106.643772, -6.275303]
        },
        "properties": {
            "address": "Rp2.500.000",
            "city": "Bali"
        }
    },
    {
        "type": "Feature",
        "geometry": {
            "type": "Point",
            "coordinates": [106.629139, -6.282222]
        },
        "properties": {
            "address": "Rp1.800.000",
            "city": "Bali"
        }
    },
    ]
};

export const roomTourAsset = [
    {
        rooms: [
            {
                roomName: 'Dining Room',
                img: DiningRoom
            },
            {
                roomName: 'Sauna',
                img: Sauna1
            },
            {
                roomName: 'Pool',
                img: Pool1
            }
        ],
        miniMap: MiniMap,
        floor: 'floor-1',
        floorName: '1st Floor'
    },
    {
        rooms: [
            {
                roomName: 'Dining Room',
                img: DiningRoom
            },
            {
                roomName: 'Sauna',
                img: Pool2
            },
        ],
        miniMap: MiniMap,
        floor: 'floor-2',
        floorName: '2nd Floor'
    }
];

export const bannerHomepage = [
    {
        title: 'Enjoy Your Stay.',
        banner: Dashboard1
    },
    {
        title: 'Enjoy Your Holiday',
        banner: Dashboard1
    },
    {
        title: 'Healing...',
        banner: Dashboard1
    },
];
