import React from 'react';

const FbIcon = (props) => {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M22 12C22 6.47656 17.5234 2 12 2C6.47656 2 2 6.47656 2 12C2 17.5234 6.47656 22 12 22C12.0586 22 12.1172 22 12.1758 21.9961V14.2148H10.0273V11.7109H12.1758V9.86719C12.1758 7.73047 13.4805 6.56641 15.3867 6.56641C16.3008 6.56641 17.0859 6.63281 17.3125 6.66406V8.89844H16C14.9648 8.89844 14.7617 9.39062 14.7617 10.1133V11.707H17.2422L16.918 14.2109H14.7617V21.6133C18.9414 20.4141 22 16.5664 22 12Z'
                fill='#787D8F'/>
        </svg>
    );
};

export default FbIcon;
