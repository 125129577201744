import React from 'react';

function ClipIcon() {
    return (
        <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M18.6295 3.32918C19.4881 4.18764 19.9608 5.32906 19.9608 6.54314C19.9608 7.75723 19.4881 8.89864 18.6295 9.75716L11.4646 16.9222C10.4013 17.9855 8.67112 17.9855 7.6077 16.9222C6.5444 15.8588 6.54446 14.1286 7.60782 13.0653L12.3259 8.34727C12.6809 7.99225 13.2564 7.99225 13.6115 8.34727C13.9665 8.70223 13.9665 9.27785 13.6115 9.63286L8.89342 14.3509C8.53889 14.7053 8.53895 15.2821 8.89336 15.6366C9.24783 15.991 9.8246 15.991 10.179 15.6366L17.3439 8.47157C17.859 7.95643 18.1427 7.2716 18.1427 6.54314C18.1427 5.81468 17.859 5.12985 17.3439 4.61478C16.2805 3.55148 14.5504 3.55141 13.487 4.61465L5.6794 12.4225C3.90721 14.1948 3.90727 17.0784 5.6794 18.8505C6.53786 19.709 7.67927 20.1819 8.89336 20.1819C10.1074 20.1819 11.2489 19.709 12.1074 18.8505L19.9152 11.0429C20.2702 10.6879 20.8458 10.6879 21.2008 11.0429C21.5558 11.3978 21.5558 11.9735 21.2008 12.3285L13.393 20.1361C12.1911 21.3381 10.5931 22 8.89336 22C7.19359 22 5.59558 21.3381 4.39374 20.1361C3.15305 18.8954 2.53277 17.2663 2.53295 15.6366C2.53307 14.0072 3.15335 12.3773 4.39374 11.1369L12.2015 3.32906C13.9737 1.55693 16.8573 1.55699 18.6295 3.32918Z'
                fill='#D6AD60'/>
        </svg>
    );
}

export default ClipIcon;
