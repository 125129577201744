import React from 'react';
import propTypes from 'prop-types';

const ToursIcon = ({ color = '', size, ...props }) => {
    return (
        <svg
            width={size || '56'}
            height={size || '56'}
            viewBox='0 0 56 56'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M18.6617 14.7007C18.6617 12.3181 16.7233 10.3796 14.3407 10.3796C11.958 10.3796 10.0195 12.3181 10.0195 14.7007C10.0195 17.0833 11.958 19.0217 14.3406 19.0217C16.7232 19.0217 18.6617 17.0833 18.6617 14.7007ZM14.3406 16.2904C13.464 16.2904 12.7508 15.5773 12.7508 14.7007C12.7508 13.8241 13.464 13.111 14.3406 13.111C15.2172 13.111 15.9303 13.8241 15.9303 14.7007C15.9303 15.5773 15.2172 16.2904 14.3406 16.2904Z'
                fill={color || '#D6AD60'}/>
            <path
                d='M51.3321 27.4802C51.3105 26.9421 50.9746 26.4669 50.4747 26.2669L35.9581 20.4602C35.5373 20.292 35.0605 20.3433 34.6852 20.5973C34.3099 20.8513 34.0851 21.275 34.0851 21.7282V41.758C32.4161 42.2513 31.122 43.6244 30.7415 45.3384H11.4245C9.96847 45.3384 8.78378 44.1537 8.78378 42.6976C8.78378 41.2416 9.96847 40.0569 11.4245 40.0569H23.7821C26.5918 40.0569 28.8777 37.771 28.8777 34.9613C28.8777 32.1516 26.5918 29.8657 23.7821 29.8657H16.749L22.6246 20.0424C23.6204 18.3776 24.1468 16.4721 24.1468 14.5322C24.1468 9.16153 19.7774 4.79211 14.4067 4.79211C9.03601 4.79211 4.6665 9.16144 4.6665 14.5321V14.5669C4.6665 16.4808 5.17981 18.3642 6.15086 20.0134L13.164 31.9241C13.3828 32.2758 13.786 32.6003 14.3361 32.5969H23.7822C25.0859 32.5969 26.1465 33.6575 26.1465 34.9612C26.1465 36.2649 25.0859 37.3255 23.7822 37.3255H11.4245C8.46237 37.3255 6.05238 39.7355 6.05238 42.6976C6.05238 45.6598 8.46237 48.0698 11.4245 48.0698H30.9305C31.6156 49.901 33.3828 51.2084 35.4501 51.2084C38.1101 51.2084 40.2741 49.0444 40.2741 46.3843C40.2741 44.1989 38.813 42.3494 36.8166 41.7586V35.5874L50.5746 28.7585C51.057 28.5188 51.3537 28.0183 51.3321 27.4802ZM8.50447 18.6275C7.78049 17.3978 7.39782 15.9937 7.39782 14.5669V14.532C7.39782 10.6674 10.5419 7.52324 14.4066 7.52324C18.2713 7.52324 21.4154 10.6674 21.4154 14.532C21.4154 15.9783 21.023 17.3989 20.2806 18.6402L14.35 28.5553L8.50447 18.6275ZM37.5427 46.3843C37.5427 47.5382 36.6038 48.4769 35.4501 48.4769C34.2964 48.4769 33.3575 47.5381 33.3575 46.3843C33.3575 45.2317 34.2941 44.2939 35.4461 44.2917C35.4477 44.2917 35.4494 44.292 35.4509 44.292C35.4524 44.292 35.4539 44.2918 35.4554 44.2918C36.6068 44.2945 37.5427 45.232 37.5427 46.3843ZM36.8166 32.5379V23.7454L46.6256 27.669L36.8166 32.5379Z'
                fill={color || '#D6AD60'}/>
        </svg>
    );
};

export default ToursIcon;

ToursIcon.propTypes = {
    color: propTypes.string,
    size: propTypes.any
};
