import React from 'react';
import propTypes from 'prop-types';

const ArrowRightIcon = ({size, ...props}) => {
    return (
        <svg
            width={size || '16'}
            height={size || '16'}
            viewBox='0 0 16 16'
            fill='#787D8F'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M2 7.49998H12.793L8.6465 3.35348C8.45112 3.15811 8.45112 2.84173 8.6465 2.64648C8.84187 2.45123 9.15825 2.45111 9.3535 2.64648L14.3535 7.64648C14.5489 7.84186 14.5489 8.15823 14.3535 8.35348L9.3535 13.3535C9.25588 13.4511 9.12787 13.5 9 13.5C8.87213 13.5 8.74412 13.4511 8.6465 13.3535C8.45112 13.1581 8.45112 12.8417 8.6465 12.6465L12.793 8.49998H2C1.72387 8.49998 1.5 8.27611 1.5 7.99998C1.5 7.72386 1.72387 7.49998 2 7.49998Z'
                fill={props.color || `#787D8F`}/>
        </svg>
    );
};

export default ArrowRightIcon;

ArrowRightIcon.propTypes = {
    color: propTypes.string,
    size: propTypes.any
};