import React from 'react';
import propTypes from 'prop-types';

const BathubIcon = ({size, ...props}) => {
    return (
        <svg
            width={size || '24'}
            height={size || '24'}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M22.3553 12.8594H4.30915V3.57812C4.30915 2.86729 4.88756 2.28906 5.59873 2.28906C6.30958 2.28906 6.88782 2.86729 6.88782 3.57812V3.92188H6.24344C5.8876 3.92188 5.5989 4.2104 5.5989 4.56641C5.5989 4.92241 5.8876 5.21094 6.24344 5.21094H8.82077C9.17678 5.21094 9.46531 4.92241 9.46531 4.56641C9.46531 4.2104 9.17678 3.92188 8.82077 3.92188H8.1769V3.57812C8.1769 2.15646 7.02025 1 5.59873 1C4.17688 1 3.02007 2.15646 3.02007 3.57812V12.8594H1.64454C1.28853 12.8594 1 13.1479 1 13.5039C1 13.8599 1.28853 14.1484 1.64454 14.1484H2.16236C2.76057 16.0171 3.35677 17.8654 3.9189 19.607C4.336 20.8992 5.52958 21.7885 6.93011 21.8753V22.3555C6.93011 22.7115 7.21865 23 7.57465 23C7.9305 23 8.2192 22.7115 8.2192 22.3555V21.8828H15.7818V22.3555C15.7818 22.7115 16.0705 23 16.4264 23C16.7824 23 17.0709 22.7115 17.0709 22.3555V21.8749C18.4706 21.7866 19.6627 20.8969 20.0786 19.6045C20.664 17.7855 21.2507 15.9669 21.8373 14.1484H22.3555C22.7113 14.1484 23 13.8599 23 13.5039C23 13.1479 22.7113 12.8594 22.3553 12.8594ZM18.8514 19.2095C18.5809 20.0504 17.7916 20.5938 16.8409 20.5938H7.1557C6.20568 20.5938 5.41662 20.0509 5.14554 19.211C4.62303 17.5925 4.07131 15.8816 3.51589 14.1484H20.4826C19.9384 15.8353 19.3946 17.5223 18.8514 19.2095Z'
                fill='#D6AD60'/>
        </svg>
    );
};

export default BathubIcon;

BathubIcon.propTypes = {
    size: propTypes.any
};