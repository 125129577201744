import React from 'react';
import propTypes from 'prop-types';

const RefrigeratorIcon = ({size, ...props}) => {
    return (
        <svg
            width={size || '24'}
            height={size || '24'}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M16.3747 2H7.5882C6.57303 2 5.74707 2.82596 5.74707 3.84113V18.9037C5.74707 19.9189 6.57303 20.7448 7.5882 20.7448H7.62985V21.4141C7.62985 21.7377 7.89215 22 8.21579 22C8.53943 22 8.80173 21.7377 8.80173 21.4141V20.7448H15.1611V21.4141C15.1611 21.7377 15.4234 22 15.7471 22C16.0707 22 16.333 21.7377 16.333 21.4141V20.7448H16.3747C17.3898 20.7448 18.2158 19.9189 18.2158 18.9037V3.84113C18.2158 2.82596 17.3898 2 16.3747 2ZM7.5882 3.17188H16.3747C16.7438 3.17188 17.0439 3.47217 17.0439 3.84113V8.27594H6.91895V3.84113C6.91895 3.47217 7.21924 3.17188 7.5882 3.17188ZM16.3747 19.5729H7.5882C7.21924 19.5729 6.91895 19.2726 6.91895 18.9037V9.44797H17.0439V18.9037C17.0439 19.2726 16.7438 19.5729 16.3747 19.5729Z'
                fill='#D6AD60'/>
            <path
                d='M15.1191 6.93744C15.4428 6.93744 15.7051 6.67514 15.7051 6.3515V5.09631C15.7051 4.77283 15.4428 4.51038 15.1191 4.51038C14.7955 4.51038 14.5332 4.77283 14.5332 5.09631V6.3515C14.5332 6.67514 14.7955 6.93744 15.1191 6.93744Z'
                fill='#D6AD60'/>
            <path
                d='M15.1191 10.7865C14.7955 10.7865 14.5332 11.0488 14.5332 11.3724V14.5104C14.5332 14.834 14.7955 15.0963 15.1191 15.0963C15.4428 15.0963 15.7051 14.834 15.7051 14.5104V11.3724C15.7051 11.0488 15.4428 10.7865 15.1191 10.7865Z'
                fill='#D6AD60'/>
        </svg>
    );
};

export default RefrigeratorIcon;

RefrigeratorIcon.propTypes = {
    size: propTypes.any
};