import React from 'react';

const CheckIcon = (props) => {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path d='M20 6L9 17L4 12' stroke='#D6AD60' strokeWidth='1.5'
                strokeLinecap='round' strokeLinejoin='round'/>
        </svg>
    );
};

export default CheckIcon;
