import React from 'react';
import propTypes from 'prop-types';

const ElevatorIcon = ({size, ...props}) => {
    return (
        <svg
            width={size || '24'}
            height={size || '24'}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M21.0625 2H2.9375C2.42059 2 2 2.42059 2 2.9375V21.0625C2 21.5794 2.42059 22 2.9375 22H21.0625C21.5794 22 22 21.5794 22 21.0625V2.9375C22 2.42059 21.5794 2 21.0625 2ZM12.5859 9.13543H17.5441V20.8281H12.5859V9.13543ZM11.4141 20.8281H6.45586V9.13543H11.4141V20.8281ZM20.8281 20.8281H18.716V8.54949C18.716 8.2259 18.4537 7.96355 18.1301 7.96355C17.8647 7.96355 6.13527 7.96355 5.86992 7.96355C5.54633 7.96355 5.28398 8.2259 5.28398 8.54949V20.8281H3.17188V3.17188H20.8281V20.8281Z'
                fill='#D6AD60'/>
            <path
                d='M7.69654 6.67425C7.96021 6.86175 8.32603 6.80007 8.51365 6.53644L8.93463 5.9446L9.3556 6.53644C9.5433 6.80026 9.9092 6.86171 10.1727 6.67425C10.4364 6.48667 10.4981 6.12085 10.3105 5.85714L9.41209 4.59413C9.17857 4.26589 8.69049 4.26616 8.45716 4.59413L7.55873 5.85714C7.37115 6.12081 7.43283 6.48663 7.69654 6.67425Z'
                fill='#D6AD60'/>
            <path
                d='M14.588 6.5364C14.8215 6.86464 15.3096 6.86437 15.5429 6.5364L16.4414 5.27339C16.629 5.00972 16.5672 4.64386 16.3036 4.45628C16.0398 4.2687 15.674 4.33042 15.4865 4.59409L15.0655 5.18593L14.6445 4.59409C14.4569 4.33038 14.0911 4.2687 13.8274 4.45628C13.5637 4.64386 13.502 5.00968 13.6896 5.27339L14.588 6.5364Z'
                fill='#D6AD60'/>
        </svg>
    );
};

export default ElevatorIcon;

ElevatorIcon.propTypes = {
    size: propTypes.any
};