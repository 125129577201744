import React from 'react';

const CrownIcon = (props) => {
    return (
        <svg
            width='48'
            height='48'
            viewBox='0 0 48 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M33.4008 39.46H14.6008C13.1208 39.46 11.6208 38.4 11.1208 37.02L2.84084 13.84C1.82085 10.92 2.56084 9.57996 3.36084 8.97996C4.16084 8.37996 5.66084 8.01996 8.18084 9.81996L15.9808 15.4C16.2208 15.54 16.4408 15.6 16.6008 15.56C16.7808 15.5 16.9208 15.34 17.0208 15.06L20.5408 5.67996C21.6008 2.87996 23.1608 2.45996 24.0008 2.45996C24.8408 2.45996 26.4008 2.87996 27.4608 5.67996L30.9808 15.06C31.0808 15.32 31.2208 15.5 31.4008 15.56C31.5808 15.62 31.8008 15.56 32.0208 15.38L39.3408 10.16C42.0208 8.23996 43.5808 8.61996 44.4408 9.23996C45.2808 9.87996 46.0608 11.3 44.9608 14.4L36.8808 37.02C36.3808 38.4 34.8808 39.46 33.4008 39.46ZM5.36084 11.62C5.40084 11.9 5.48084 12.3 5.68084 12.82L13.9608 36C14.0408 36.2 14.4008 36.46 14.6008 36.46H33.4008C33.6208 36.46 33.9808 36.2 34.0408 36L42.1208 13.4C42.4008 12.64 42.4808 12.12 42.5008 11.82C42.2008 11.92 41.7408 12.14 41.0808 12.62L33.7608 17.84C32.7608 18.54 31.5808 18.76 30.5208 18.44C29.4608 18.12 28.6008 17.28 28.1608 16.14L24.6408 6.75996C24.3808 6.05996 24.1408 5.71996 24.0008 5.55996C23.8608 5.71996 23.6208 6.05996 23.3608 6.73996L19.8408 16.12C19.4208 17.26 18.5608 18.1 17.4808 18.42C16.4208 18.74 15.2208 18.52 14.2408 17.82L6.44084 12.24C5.98084 11.92 5.62084 11.72 5.36084 11.62Z'
                fill='#D6AD60'/>
            <path
                d='M35 45.5H13C12.18 45.5 11.5 44.82 11.5 44C11.5 43.18 12.18 42.5 13 42.5H35C35.82 42.5 36.5 43.18 36.5 44C36.5 44.82 35.82 45.5 35 45.5Z'
                fill='#D6AD60'/>
            <path
                d='M29 29.5H19C18.18 29.5 17.5 28.82 17.5 28C17.5 27.18 18.18 26.5 19 26.5H29C29.82 26.5 30.5 27.18 30.5 28C30.5 28.82 29.82 29.5 29 29.5Z'
                fill='#D6AD60'/>
        </svg>
    );
};

export default CrownIcon;
