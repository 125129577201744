import React from 'react';
import propTypes from 'prop-types';

const SpeakerIcon = ({size, ...props}) => {
    return (
        <svg
            width={size || '24'}
            height={size || '24'}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M16.8967 2H7.10352C6.13426 2 5.3457 2.78855 5.3457 3.75781V20.2422C5.3457 21.2114 6.13426 22 7.10352 22H16.8967C17.8659 22 18.6545 21.2114 18.6545 20.2422V3.75781C18.6545 2.78855 17.866 2 16.8967 2ZM16.8967 20.8281H7.10352C6.78043 20.8281 6.51758 20.5653 6.51758 20.2422V3.75781C6.51758 3.43473 6.78043 3.17188 7.10352 3.17188H16.8967C17.2198 3.17188 17.4826 3.43473 17.4826 3.75781V20.2422H17.4827C17.4827 20.5653 17.2198 20.8281 16.8967 20.8281Z'
                fill='#D6AD60'/>
            <path
                d='M11.9999 4.24777C10.6102 4.24777 9.47949 5.37844 9.47949 6.7682C9.47949 8.15797 10.6102 9.28867 11.9999 9.28867C13.3897 9.28867 14.5204 8.15801 14.5204 6.76824C14.5204 5.37848 13.3897 4.24777 11.9999 4.24777ZM11.9999 8.1168C11.2563 8.1168 10.6514 7.51183 10.6514 6.76824C10.6514 6.02465 11.2563 5.41965 11.9999 5.41965C12.7435 5.41965 13.3485 6.02461 13.3485 6.7682C13.3485 7.5118 12.7435 8.1168 11.9999 8.1168Z'
                fill='#D6AD60'/>
            <path
                d='M11.9998 10.1629C9.45264 10.1629 7.38037 12.2352 7.38037 14.7824C7.38037 17.3296 9.45264 19.4018 11.9998 19.4018C14.547 19.4018 16.6193 17.3295 16.6193 14.7824C16.6193 12.2352 14.547 10.1629 11.9998 10.1629ZM11.9998 18.2299C10.0988 18.2299 8.55225 16.6834 8.55225 14.7824C8.55225 12.8814 10.0988 11.3348 11.9998 11.3348C13.9008 11.3348 15.4474 12.8814 15.4474 14.7824C15.4474 16.6834 13.9008 18.2299 11.9998 18.2299Z'
                fill='#D6AD60'/>
            <path
                d='M12.0001 12.8335C10.9255 12.8335 10.0513 13.7078 10.0513 14.7824C10.0513 15.8569 10.9255 16.7312 12.0001 16.7312C13.0747 16.7312 13.949 15.8569 13.949 14.7824C13.949 13.7078 13.0747 12.8335 12.0001 12.8335ZM12.0001 15.5593C11.5717 15.5593 11.2231 15.2108 11.2231 14.7824C11.2231 14.3539 11.5717 14.0054 12.0001 14.0054C12.4286 14.0054 12.7771 14.3539 12.7771 14.7824C12.7771 15.2108 12.4286 15.5593 12.0001 15.5593Z'
                fill='#D6AD60'/>
        </svg>
    );
};

export default SpeakerIcon;

SpeakerIcon.propTypes = {
    size: propTypes.any
};