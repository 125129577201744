import React from 'react';
import propTypes from 'prop-types';

const DumbelsIcon = ({color='', size, ...props}) => {
    return (
        <svg
            width={size || '25'}
            height={size || '24'}
            viewBox='0 0 25 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M21.4555 6.7202H21.0402C20.8725 6.7202 20.7104 6.74412 20.5566 6.78787C20.3998 5.97015 19.6796 5.35026 18.8169 5.35026H18.4017C17.4247 5.35026 16.6299 6.14509 16.6299 7.12199V9.98171H8.37072V6.77174C8.37072 5.79479 7.57588 5 6.59898 5H6.18378C5.32106 5 4.60081 5.6199 4.4441 6.43761C4.29023 6.39387 4.12811 6.36994 3.96042 6.36994H3.54522C2.56827 6.36994 1.77344 7.16469 1.77344 8.14168V15.508C1.77344 16.485 2.56827 17.2798 3.54517 17.2798H3.96038C4.12807 17.2798 4.29019 17.2558 4.44405 17.2121C4.60081 18.0298 5.32106 18.6497 6.18373 18.6497H6.59894C7.57588 18.6497 8.37068 17.8549 8.37068 16.878V14.3051H16.6298V17.2283C16.6298 18.2052 17.4247 19 18.4016 19H18.8168C19.6795 19 20.3998 18.3801 20.5565 17.5624C20.7104 17.6061 20.8725 17.6301 21.0402 17.6301H21.4554C22.4323 17.6301 23.2272 16.8352 23.2272 15.8583V8.49194C23.2272 7.51499 22.4324 6.7202 21.4555 6.7202ZM4.41204 15.508C4.41204 15.7571 4.20944 15.9597 3.96038 15.9597H3.54517C3.29611 15.9597 3.09351 15.7571 3.09351 15.508V8.14168C3.09351 7.89261 3.29611 7.69002 3.54517 7.69002H3.96038C4.20944 7.69002 4.41204 7.89261 4.41204 8.14168V15.508ZM7.05064 16.878C7.05064 17.1271 6.84805 17.3297 6.59898 17.3297H6.18378C5.93471 17.3297 5.73212 17.1271 5.73212 16.878V6.77165C5.73212 6.52259 5.93475 6.31999 6.18378 6.31999H6.59898C6.84805 6.31999 7.05064 6.52259 7.05064 6.77165V16.878ZM16.6299 12.9851H8.37072V11.3017H16.6299L16.6299 12.9851ZM19.2685 17.2283C19.2685 17.4774 19.0659 17.68 18.8168 17.68H18.4016C18.1525 17.68 17.9499 17.4774 17.9499 17.2283V7.12195C17.9499 6.87289 18.1525 6.67029 18.4016 6.67029H18.8168C19.0659 6.67029 19.2685 6.87289 19.2685 7.12195V17.2283ZM21.9071 15.8583C21.9071 16.1073 21.7045 16.3099 21.4555 16.3099H21.0402C20.7912 16.3099 20.5886 16.1073 20.5886 15.8583V8.49194C20.5886 8.24287 20.7911 8.04027 21.0402 8.04027H21.4555C21.7045 8.04027 21.9071 8.24287 21.9071 8.49194V15.8583Z'
                fill={color || '#787D8F'}/>
        </svg>
    );
};

export default DumbelsIcon;

DumbelsIcon.propTypes = {
    color: propTypes.string,
    size: propTypes.any
};