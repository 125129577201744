import React from 'react';

const PhoneIcon = (props) => {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path d='M21.3567 16.396C21.3213 16.3667 17.3287 13.5093 16.2453 13.6967C15.7247 13.7887 15.4267 14.1433 14.83 14.8547C14.734 14.9693 14.5027 15.2433 14.324 15.4387C13.9469 15.3158 13.5791 15.1661 13.2233 14.9907C11.387 14.0967 9.90333 12.613 9.00933 10.7767C8.83391 10.4209 8.68419 10.0531 8.56133 9.676C8.75733 9.49667 9.032 9.26533 9.14933 9.16667C9.85667 8.57333 10.2113 8.27533 10.3033 7.754C10.492 6.67467 7.63333 2.67867 7.604 2.64267C7.47382 2.45804 7.30426 2.30462 7.10758 2.19348C6.9109 2.08234 6.69199 2.01626 6.46667 2C5.308 2 2 6.29067 2 7.014C2 7.056 2.06067 11.3253 7.32533 16.6807C12.6747 21.9393 16.944 22 16.986 22C17.7093 22 22 18.692 22 17.5333C21.9838 17.3079 21.9176 17.089 21.8063 16.8923C21.6951 16.6956 21.5415 16.526 21.3567 16.396ZM16.9127 20.6627C16.33 20.6147 12.7473 20.142 8.268 15.7413C3.84467 11.238 3.384 7.64533 3.338 7.08867C4.21185 5.7171 5.26719 4.47001 6.47533 3.38133C6.502 3.408 6.53733 3.448 6.58267 3.5C7.50922 4.76482 8.30738 6.11885 8.96533 7.542C8.75137 7.75725 8.52522 7.96003 8.288 8.14933C7.92013 8.42963 7.58232 8.74733 7.28 9.09733L7.118 9.324L7.166 9.598C7.30709 10.2092 7.52317 10.8005 7.80933 11.3587C8.83459 13.464 10.5358 15.165 12.6413 16.19C13.1994 16.4766 13.7908 16.6929 14.402 16.834L14.676 16.882L14.9027 16.72C15.254 16.4163 15.573 16.0772 15.8547 15.708C16.0633 15.4587 16.3427 15.126 16.448 15.032C17.8752 15.6893 19.2327 16.4884 20.5 17.4173C20.5553 17.464 20.594 17.5 20.62 17.5233C19.5315 18.7318 18.2844 19.7874 16.9127 20.6613V20.6627Z'
                fill='#D6AD60' />
        </svg>
    );
};

export default PhoneIcon;