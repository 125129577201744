import React from 'react';

const XIcon = (props) => {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M13.8617 10.4686L21.1471 2H19.4207L13.0948 9.3532L8.04237 2H2.21497L9.85527 13.1193L2.21497 22H3.94146L10.6217 14.2348L15.9575 22H21.7849L13.8613 10.4686H13.8617ZM11.4971 13.2173L10.723 12.1101L4.56354 3.29967H7.21533L12.1861 10.4099L12.9602 11.5172L19.4215 20.7594H16.7697L11.4971 13.2177V13.2173Z'
                fill='#787D8F'/>
        </svg>
    );
};

export default XIcon;
