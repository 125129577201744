import React from 'react';
import propTypes from 'prop-types';

function CautionFillIcon({...props}) {
    return (
        <svg
            width='17'
            height='16'
            viewBox='0 0 17 16'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M8.49968 1.33325C12.1791 1.33325 15.1663 4.32054 15.1663 7.99992C15.1663 11.6793 12.1791 14.6666 8.49968 14.6666C4.82029 14.6666 1.83301 11.6793 1.83301 7.99992C1.83301 4.32054 4.82029 1.33325 8.49968 1.33325ZM7.72448 5.28674L8.03456 9.00767C8.03456 9.26442 8.24293 9.47279 8.49968 9.47279C8.75642 9.47279 8.96479 9.26442 8.96479 9.00767L9.27487 5.28674C9.27487 5.03 9.11983 4.51155 8.49968 4.51155C7.87952 4.51155 7.72448 5.03 7.72448 5.28674ZM8.49968 10.248C8.15735 10.248 7.87952 10.5258 7.87952 10.8681C7.87952 11.2105 8.15735 11.4883 8.49968 11.4883C8.842 11.4883 9.11983 11.2105 9.11983 10.8681C9.11983 10.5258 8.842 10.248 8.49968 10.248Z'
                fill='#EA3D3D'/>
        </svg>
    );
}

export default CautionFillIcon;

CautionFillIcon.propTypes = {
    color: propTypes.string,
};