import React from 'react';
import propTypes from 'prop-types';

const HangerIcon = ({size, ...props}) => {
    return (
        <svg
            width={size || '24'}
            height={size || '24'}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M21.2702 15.9503L13.7015 10.6522C13.3628 10.4151 12.9843 10.2538 12.5856 10.174V9.56387C13.5948 9.30319 14.3425 8.38501 14.3425 7.29551C14.3425 6.00386 13.2916 4.95302 12 4.95302C10.7083 4.95302 9.65749 6.00386 9.65749 7.29551C9.65749 7.61893 9.91969 7.88114 10.2431 7.88114C10.5665 7.88114 10.8287 7.61893 10.8287 7.29551C10.8287 6.64969 11.3542 6.12427 12 6.12427C12.6458 6.12427 13.1712 6.64969 13.1712 7.29551C13.1712 7.94134 12.6458 8.46676 12 8.46676C11.6766 8.46676 11.4144 8.72896 11.4144 9.05238V10.174C11.0157 10.2538 10.6372 10.4151 10.2984 10.6522L2.72975 15.9503C2.10856 16.3851 1.85448 17.1334 2.08244 17.8566C2.31041 18.5797 2.94764 19.047 3.70591 19.047H20.2941C21.0524 19.047 21.6896 18.5797 21.9176 17.8566C22.1455 17.1334 21.8914 16.3851 21.2702 15.9503ZM20.8005 17.5044C20.7283 17.7335 20.5343 17.8757 20.2941 17.8757H3.70591C3.46576 17.8757 3.27169 17.7334 3.1995 17.5044C3.12727 17.2754 3.20465 17.0475 3.40142 16.9098L10.9701 11.6117C11.2735 11.3993 11.6297 11.287 12 11.287C12.3704 11.287 12.7265 11.3993 13.0299 11.6117L20.5986 16.9098C20.7953 17.0476 20.8727 17.2754 20.8005 17.5044Z'
                fill='#D6AD60'/>
        </svg>
    );
};

export default HangerIcon;

HangerIcon.propTypes = {
    size: propTypes.any
};