import React from 'react';
import propTypes from 'prop-types';

const AcIcon = ({size, ...props}) => {
    return (
        <svg
            width={size || '24'}
            height={size || '24'}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M20.9346 5H3.06448C2.03326 5 1.19434 5.83892 1.19434 6.87011V12.8961C1.19434 13.9273 2.03326 14.7662 3.06448 14.7662H20.9346C21.9658 14.7662 22.8048 13.9273 22.8048 12.8961V6.87011C22.8048 5.83892 21.9659 5 20.9346 5ZM5.29825 13.5195L5.76579 11.4416H18.2334L18.7009 13.5195H5.29825ZM21.558 12.8961C21.558 13.2398 21.2784 13.5195 20.9347 13.5195H19.9788L19.3402 10.6814C19.2762 10.3969 19.0236 10.1948 18.7321 10.1948H5.26706C4.97549 10.1948 4.72287 10.3969 4.65889 10.6814L4.02028 13.5195H3.06448C2.72074 13.5195 2.44112 13.2398 2.44112 12.8961V6.87011C2.44112 6.52636 2.72074 6.24674 3.06448 6.24674H20.9347C21.2784 6.24674 21.558 6.52636 21.558 6.87011V12.8961Z'
                fill='#D6AD60'/>
            <path
                d='M15.3246 8.11688H14.4935C14.1492 8.11688 13.8701 8.396 13.8701 8.74025C13.8701 9.0845 14.1492 9.36362 14.4935 9.36362H15.3246C15.6689 9.36362 15.948 9.0845 15.948 8.74025C15.948 8.396 15.6689 8.11688 15.3246 8.11688Z'
                fill='#D6AD60'/>
            <path
                d='M18.2338 8.11688H17.4027C17.0584 8.11688 16.7793 8.396 16.7793 8.74025C16.7793 9.0845 17.0584 9.36362 17.4027 9.36362H18.2338C18.5781 9.36362 18.8572 9.0845 18.8572 8.74025C18.8572 8.396 18.5781 8.11688 18.2338 8.11688Z'
                fill='#D6AD60'/>
            <path
                d='M12.4157 17.6059C12.4157 17.4323 12.4481 17.3783 12.5346 17.2341C12.6528 17.0373 12.8313 16.7398 12.8313 16.2208C12.8313 15.8765 12.5521 15.5974 12.2079 15.5974C11.8636 15.5974 11.5845 15.8765 11.5845 16.2208C11.5845 16.3944 11.5522 16.4484 11.4656 16.5926C11.3474 16.7895 11.1689 17.0869 11.1689 17.6059C11.1689 18.1249 11.3474 18.4224 11.4655 18.6193C11.5521 18.7637 11.5845 18.8176 11.5845 18.9913C11.5845 19.1649 11.5522 19.219 11.4655 19.3632C11.3474 19.5601 11.1689 19.8576 11.1689 20.3766C11.1689 20.7209 11.4481 21 11.7923 21C12.1366 21 12.4157 20.7209 12.4157 20.3766C12.4157 20.2029 12.4481 20.1489 12.5347 20.0047C12.6528 19.8078 12.8313 19.5103 12.8313 18.9913C12.8313 18.4722 12.6528 18.1748 12.5347 17.9779C12.4481 17.8336 12.4157 17.7796 12.4157 17.6059Z'
                fill='#D6AD60'/>
            <path
                d='M9.09049 17.6059C9.09049 17.4323 9.12286 17.3783 9.20943 17.2341C9.32757 17.0373 9.50607 16.7398 9.50607 16.2208C9.50607 15.8765 9.22695 15.5974 8.8827 15.5974C8.53845 15.5974 8.25933 15.8765 8.25933 16.2208C8.25933 16.3944 8.22696 16.4484 8.14039 16.5926C8.02225 16.7895 7.84375 17.0869 7.84375 17.6059C7.84375 18.1249 8.02225 18.4224 8.14034 18.6193C8.22691 18.7637 8.25933 18.8176 8.25933 18.9913C8.25933 19.1649 8.22696 19.219 8.14034 19.3632C8.02225 19.5601 7.84375 19.8576 7.84375 20.3766C7.84375 20.7209 8.12287 21 8.46712 21C8.81137 21 9.09049 20.7209 9.09049 20.3766C9.09049 20.2029 9.12286 20.1489 9.20947 20.0047C9.32757 19.8078 9.50607 19.5103 9.50607 18.9913C9.50607 18.4722 9.32757 18.1748 9.20947 17.9779C9.1229 17.8336 9.09049 17.7796 9.09049 17.6059Z'
                fill='#D6AD60'/>
            <path
                d='M15.7399 17.6059C15.7399 17.4323 15.7723 17.3783 15.8588 17.2341C15.977 17.0373 16.1555 16.7398 16.1555 16.2208C16.1555 15.8765 15.8764 15.5974 15.5321 15.5974C15.1879 15.5974 14.9087 15.8765 14.9087 16.2208C14.9087 16.3944 14.8764 16.4484 14.7898 16.5926C14.6717 16.7895 14.4932 17.0869 14.4932 17.6059C14.4932 18.1249 14.6717 18.4224 14.7898 18.6193C14.8763 18.7637 14.9087 18.8176 14.9087 18.9913C14.9087 19.1649 14.8764 19.219 14.7898 19.3632C14.6717 19.5601 14.4932 19.8576 14.4932 20.3766C14.4932 20.7209 14.7723 21 15.1165 21C15.4608 21 15.7399 20.7209 15.7399 20.3766C15.7399 20.2029 15.7723 20.1489 15.8589 20.0047C15.977 19.8078 16.1555 19.5103 16.1555 18.9913C16.1555 18.4722 15.977 18.1748 15.8589 17.9779C15.7723 17.8336 15.7399 17.7796 15.7399 17.6059Z'
                fill='#D6AD60'/>
        </svg>
    );
};

export default AcIcon;

AcIcon.propTypes = {
    size: propTypes.any
};