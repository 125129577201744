import React from 'react';

const BuildingIcon = (props) => {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M12.4997 22.7499H4.07973C2.50973 22.7499 1.21973 21.4899 1.21973 19.9299V5.08994C1.21973 3.55994 1.78973 2.36994 2.82973 1.71994C3.87973 1.05994 5.23973 1.07994 6.64973 1.77994L11.0897 3.95994C12.2997 4.55994 13.2497 6.06994 13.2497 7.40994V21.9999C13.2497 22.4099 12.9097 22.7499 12.4997 22.7499ZM4.55973 2.74994C4.20973 2.74994 3.88973 2.82994 3.61973 2.99994C3.03973 3.35994 2.71973 4.10994 2.71973 5.08994V19.9299C2.71973 20.6599 3.32973 21.2499 4.07973 21.2499H11.7497V7.40994C11.7497 6.63994 11.1297 5.64994 10.4197 5.30994L5.97973 3.12994C5.46973 2.87994 4.98973 2.74994 4.55973 2.74994Z'
                fill='#D6AD60'/>
            <path
                d='M18.81 22.75H12.5C12.09 22.75 11.75 22.41 11.75 22V10.42C11.75 10.19 11.85 9.98002 12.03 9.84002C12.21 9.70002 12.43 9.64002 12.66 9.69002L13.13 9.79002L19.67 11.25C21.66 11.69 22.66 12.86 22.72 14.85C22.73 14.85 22.73 14.95 22.73 15.06V18.84C22.72 21.4 21.37 22.75 18.81 22.75ZM13.25 21.25H18.81C20.57 21.25 21.22 20.6 21.22 18.84V14.98C21.17 13.61 20.65 13 19.34 12.71L13.25 11.35V21.25Z'
                fill='#D6AD60'/>
            <path
                d='M8.97 9.75H5.5C5.09 9.75 4.75 9.41 4.75 9C4.75 8.59 5.09 8.25 5.5 8.25H8.97C9.38 8.25 9.72 8.59 9.72 9C9.72 9.41 9.39 9.75 8.97 9.75Z'
                fill='#D6AD60'/>
            <path
                d='M8.97 13.75H5.5C5.09 13.75 4.75 13.41 4.75 13C4.75 12.59 5.09 12.25 5.5 12.25H8.97C9.38 12.25 9.72 12.59 9.72 13C9.72 13.41 9.39 13.75 8.97 13.75Z'
                fill='#D6AD60'/>
            <path
                d='M15.2197 17.75C13.5697 17.75 12.2197 16.4 12.2197 14.75V10.52C12.2197 10.29 12.3197 10.08 12.4997 9.92998C12.6797 9.77998 12.9097 9.72998 13.1297 9.77998L17.6297 10.79C17.9697 10.87 18.2197 11.17 18.2197 11.52V14.74C18.2197 16.4 16.8797 17.75 15.2197 17.75ZM13.7197 11.46V14.75C13.7197 15.58 14.3897 16.25 15.2197 16.25C16.0497 16.25 16.7197 15.58 16.7197 14.75V12.13L13.7197 11.46Z'
                fill='#D6AD60'/>
            <path
                d='M19.7197 17.75C18.0697 17.75 16.7197 16.4 16.7197 14.75V11.53C16.7197 11.3 16.8197 11.09 16.9997 10.95C17.1797 10.81 17.4097 10.75 17.6297 10.8L19.6597 11.25C21.6497 11.69 22.6497 12.86 22.7097 14.85V14.91C22.6297 16.5 21.3197 17.75 19.7197 17.75ZM18.2197 12.46V14.75C18.2197 15.58 18.8897 16.25 19.7197 16.25C20.5097 16.25 21.1597 15.64 21.2097 14.86C21.1597 13.6 20.6397 13 19.3397 12.71L18.2197 12.46Z'
                fill='#D6AD60'/>
        </svg>
    );
};

export default BuildingIcon;
