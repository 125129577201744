import React from 'react';

const ChevronLeftIcon = (props) => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M13.125 16.875C12.9652 16.875 12.8052 16.8139 12.6832 16.6919L6.43316 10.4419C6.18895 10.1976 6.18895 9.80217 6.43316 9.5581L12.6832 3.3081C12.9274 3.06388 13.3229 3.06388 13.5669 3.3081C13.811 3.55232 13.8111 3.94779 13.5669 4.19185L7.75879 9.99998L13.5669 15.8081C13.8111 16.0523 13.8111 16.4478 13.5669 16.6919C13.4449 16.8139 13.2849 16.875 13.125 16.875Z'
                fill='#2B2C32'/>
        </svg>
    );
};

export default ChevronLeftIcon;
