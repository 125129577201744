const { lazy } = require("react");

export const Homepage = lazy(() => import('~/screens/homepage'));
export const AboutUsPage = lazy(() => import('~/screens/about'));
export const ContactUsPage = lazy(() => import('~/screens/contact'));
export const PrivacyPage = lazy(() => import('~/screens/privacy'));
export const ArticlesPage = lazy(() => import('~/screens/articles'));
export const ArticleDetailPage = lazy(() => import('~/screens/articles/detail'));
export const VillaDetailPage = lazy(() => import('~/screens/villa-detail'));
export const MyBookPage = lazy(() => import('~/screens/mybook'));
export const ExperiencesPage = lazy(() => import('~/screens/experience'));
export const DetailExperiences = lazy(() => import('~/screens/experience/detail/detail'));
export const Cart = lazy(() => import('~/screens/Cart'));
export const Checkout = lazy(() => import('~/screens/Cart/Checkout'));
export const Terms = lazy(() => import('~/screens/terms'));
export const MyBookDetailPage = lazy(() => import('~/screens/mybook/MyBookDetail'));


export const ROUTES = {
    Homepage: '/',
    Detail: '/detail',
    SearchResult: '/SearchResult',
    About: '/about',
    Contact: '/contact',
    Privacy: '/privacy',
    Experiences: '/experiences',
    DetailExperiences: '/detail-experiences',
    MyBook: '/mybook',
    MyBookDetail: '/mybookdetail',
    Articles: '/articles',
    ArticleDetail: '/articles/:slug/:slug',
    Cart: '/cart',
    Checkout: '/checkout',
    VillaDetail: '/villa-detail/',
    Terms : '/terms'
};