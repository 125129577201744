import React from 'react';

const DiningRoomIcon = (props) => {
    return (
        <svg
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M4.92969 10.8281H3.17187V5.55469C3.17187 5.23105 2.90958 4.96875 2.58594 4.96875C2.2623 4.96875 2 5.23105 2 5.55469V11.4136V11.4141V13.7578V13.7583V18.4453C2 18.769 2.2623 19.0312 2.58594 19.0312C2.90958 19.0312 3.17187 18.769 3.17187 18.4453V14.3437H6.6875V18.4453C6.6875 18.769 6.9498 19.0312 7.27344 19.0312C7.59708 19.0312 7.85937 18.769 7.85937 18.4453V13.7969C7.85937 13.7903 7.85861 13.7839 7.85846 13.7773C7.85861 13.7708 7.85937 13.7644 7.85937 13.7578C7.85937 12.1424 6.54514 10.8281 4.92969 10.8281Z'
                fill='white'/>
            <path
                d='M21.4141 4.96875C21.0904 4.96875 20.8281 5.23105 20.8281 5.55469V10.8281H19.0703C17.4549 10.8281 16.1406 12.1424 16.1406 13.7578C16.1406 13.7644 16.1414 13.7708 16.1415 13.7773C16.1414 13.7839 16.1406 13.7903 16.1406 13.7969V18.4453C16.1406 18.769 16.4029 19.0312 16.7266 19.0312C17.0502 19.0312 17.3125 18.769 17.3125 18.4453V14.3437H20.8281V18.4453C20.8281 18.769 21.0904 19.0312 21.4141 19.0312C21.7377 19.0312 22 18.769 22 18.4453V5.55469C22 5.23105 21.7377 4.96875 21.4141 4.96875Z'
                fill='white'/>
            <path
                d='M13.9531 17.8594H12.5859V9.1875H17.0781C17.4018 9.1875 17.6641 8.9252 17.6641 8.60156C17.6641 8.27792 17.4018 8.01562 17.0781 8.01562H6.92187C6.59824 8.01562 6.33594 8.27792 6.33594 8.60156C6.33594 8.9252 6.59824 9.1875 6.92187 9.1875H11.4141V17.8594H10.0469C9.72324 17.8594 9.46094 18.1217 9.46094 18.4453C9.46094 18.769 9.72324 19.0312 10.0469 19.0312H13.9531C14.2768 19.0312 14.5391 18.769 14.5391 18.4453C14.5391 18.1217 14.2768 17.8594 13.9531 17.8594Z'
                fill='white'/>
        </svg>
    );
};

export default DiningRoomIcon;
