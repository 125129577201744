import React from 'react';

function CoinIcon() {
    return (
        <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M15.4998 15.7499C15.3198 15.7499 15.1198 15.7399 14.9398 15.7299C14.5698 15.7099 14.2697 15.4099 14.2397 15.0399C14.0197 12.2499 11.7498 9.98986 8.96976 9.76986C8.59976 9.73986 8.29975 9.43988 8.27975 9.06988C8.26975 8.88988 8.25977 8.68989 8.25977 8.50989C8.25977 4.50989 11.5098 1.25989 15.5098 1.25989C19.5098 1.25989 22.7598 4.50989 22.7598 8.50989C22.7598 12.5099 19.4998 15.7499 15.4998 15.7499ZM9.74976 8.35986C12.7298 8.88986 15.1198 11.2699 15.6398 14.2499C18.7498 14.1699 21.2498 11.6299 21.2498 8.49988C21.2498 5.32988 18.6698 2.74988 15.4998 2.74988C12.3798 2.74988 9.82976 5.24986 9.74976 8.35986Z'
                fill='#D6AD60'/>
            <path
                d='M8.5 22.7501C4.5 22.7501 1.25 19.5001 1.25 15.5001C1.25 11.5001 4.5 8.25012 8.5 8.25012C8.68 8.25012 8.88 8.26011 9.06 8.27011C12.59 8.55011 15.45 11.4101 15.72 14.9201C15.73 15.1201 15.74 15.3201 15.74 15.5001C15.75 19.5001 12.5 22.7501 8.5 22.7501ZM8.5 9.75012C5.33 9.75012 2.75 12.3301 2.75 15.5001C2.75 18.6701 5.33 21.2501 8.5 21.2501C11.67 21.2501 14.25 18.6701 14.25 15.5001C14.25 15.3401 14.24 15.1801 14.23 15.0201C14.01 12.2501 11.74 9.98013 8.95999 9.76013C8.81999 9.76013 8.66 9.75012 8.5 9.75012Z'
                fill='#D6AD60'/>
            <path
                d='M8.5 18.7504C8.23 18.7504 7.97 18.6003 7.84 18.3603L7.06 16.9404L5.64001 16.1604C5.40001 16.0304 5.25 15.7804 5.25 15.5004C5.25 15.2204 5.40001 14.9704 5.64001 14.8404L7.06 14.0604L7.84 12.6404C8.1 12.1604 8.89 12.1604 9.16 12.6404L9.94 14.0604L11.36 14.8404C11.6 14.9704 11.75 15.2204 11.75 15.5004C11.75 15.7804 11.6 16.0304 11.36 16.1604L9.94 16.9404L9.16 18.3603C9.03 18.6003 8.77 18.7504 8.5 18.7504ZM7.56 15.5004L7.97 15.7304C8.1 15.8004 8.19999 15.9004 8.26999 16.0304L8.5 16.4404L8.73001 16.0304C8.80001 15.9004 8.9 15.8004 9.03 15.7304L9.44 15.5004L9.03 15.2703C8.9 15.2003 8.80001 15.1004 8.73001 14.9704L8.5 14.5604L8.26999 14.9704C8.19999 15.1004 8.1 15.2003 7.97 15.2703L7.56 15.5004Z'
                fill='#D6AD60'/>
        </svg>
    );
}

export default CoinIcon;
