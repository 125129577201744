import React from 'react';
import ColorLogo from '~/assets/image/ColorLogo';
import LeafDecoration from '~/assets/image/LeafDecoration';
import { footerList, socialMediaIcon } from '~/config/constant';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ROUTES } from '~/config/routes/Routes';

const Footer = ({isQuestion = true, classNames=''}) => {
    return (
        <footer className={`flex flex-col gap-y-12 sm:gap-y-20 ${classNames}`}>
            {isQuestion && 
                <div className='relative bg-footer-hero bg-cover bg-center w-full h-[346px]'>
                    <div className='absolute inset-0 bg-black opacity-50' />
                    <div className='absolute -translate-y-1/2 top-1/2 left-0 right-0 flex flex-col justify-center items-center text-white space-y-6'>
                        <p className='headerText max-sm:text-[35px] text-white'>Have Questions?</p>
                        <p className='__ff-lato max-sm:text-[14px] text-white'>{`Don't Hesitate to Reach Out and Ask Us Anything`}</p>
                        <Link to={ROUTES.Contact} className='px-5 py-3 bg-colors-blue400 mdText font-bold text-white max-sm:text-[14px]'>
                            Contact Us
                        </Link>
                    </div>
                </div>
            }

            <div className='relative px-4 lg:px-0'>
                <div className='container mx-auto'>
                    <div className='flex flex-col sm:flex-row gap-x-4 lg:gap-x-28 xl:gap-x-20 mb-8 max-sm:gap-y-12'>
                        <div className='flex flex-col xl:w-[500px] max-sm:items-center'>
                            <div className='sm:hidden'>
                                <ColorLogo width='132' height='80' />
                            </div>
                            <div className='max-sm:hidden'>
                                <ColorLogo />
                            </div>
                            <div className='flex flex-col pt-5 smText text-colors-gray300 max-sm:hidden'>
                                <p>Jalan Pahlawan No. 456, Denpasar Barat, Denpasar, Bali, 80117</p>
                                <p>Indonesia</p>
                                <p>(0411) 8272 3922 | 0813 2333 2338</p>
                                <p>info@nuswapada.com</p>
                            </div>
                            <div className='pt-10 flex flex-row gap-x-4 max-sm:hidden'>
                                {socialMediaIcon.map((val, i) => (
                                    <div key={i}>
                                        <val.icon />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='z-10 xl:flex-1'>
                            {footerList.map((category, index) => (
                                <div key={index} className='grid grid-cols-2 sm:grid-cols-3 gap-x-8 lg:gap-x-[60px] max-sm:gap-y-4'>
                                    {Object.entries(category).map(([title, items]) => (
                                        <div key={title} className='flex flex-col gap-y-0 sm:gap-y-5'>
                                            <p className='lgText neuton text-colors-blue400 py-5'>{title}</p>
                                            <div className='flex flex-col gap-y-3'>
                                                {items.map((item, itemIndex) => (
                                                    <p className='smText text-colors-gray300' key={itemIndex}>{item}</p>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                        <div className='flex flex-row justify-center gap-x-4 z-[5] sm:hidden'>
                            {socialMediaIcon.map((val, i) => (
                                <div key={i}>
                                    <val.icon />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='absolute bottom-0 right-0 pr-5 pb-3 max-sm:hidden'>
                    <LeafDecoration />
                </div>
            </div>
        </footer>
    );
};

export default Footer;


Footer.propTypes = {
    isQuestion: propTypes.bool,
    classNames: propTypes.string
};