import React, { Suspense } from 'react';
import { Homepage, ROUTES, AboutUsPage, ContactUsPage, PrivacyPage, ArticlesPage, ArticleDetailPage, MyBookPage, ExperiencesPage, DetailExperiences, Cart, Checkout, VillaDetailPage,Terms, MyBookDetailPage } from "./Routes";
import { Routes, Route, BrowserRouter  } from "react-router-dom";
import SearchResult from '~/screens/SearchResult';

function AppRoutes() {
    return (
        <BrowserRouter basename={process.env.REACT_APP_WEB_URL}>
            <Suspense>
                <Routes>
                    <Route path={ROUTES.Homepage} index={true} element={<Homepage />} />
                    <Route path={ROUTES.SearchResult} element={<SearchResult />} />
                    <Route path={ROUTES.About} element={<AboutUsPage />} />
                    <Route path={ROUTES.Contact} element={<ContactUsPage />} />
                    <Route path={ROUTES.Privacy} element={<PrivacyPage />} />
                    <Route path={ROUTES.Experiences} element={<ExperiencesPage />} />
                    <Route path={ROUTES.DetailExperiences} element={<DetailExperiences />} />
                    <Route path={ROUTES.Cart} element={<Cart />} />
                    <Route path={ROUTES.Checkout} element={<Checkout />} />
                    <Route path={ROUTES.Articles} element={<ArticlesPage />} />
                    <Route path={ROUTES.ArticleDetail} element={<ArticleDetailPage />} />
                    <Route path={`${ROUTES.VillaDetail}:id`} element={<VillaDetailPage />} />
                    <Route path={ROUTES.MyBook} element={<MyBookPage />} />
                    <Route path={ROUTES.MyBookDetail} element={<MyBookDetailPage />} />
                    <Route path={ROUTES.Terms} element={<Terms />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

export default AppRoutes;