import React from 'react';
import propTypes from 'prop-types';

function BedIcon({color, size, ...props}) {
    return (
        <svg
            width={size || '24'}
            height={size || '24'}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M21.926 12.0288V11.6098C21.926 10.8379 21.5264 10.158 20.9235 9.76488V5.19439C20.9235 3.99186 19.9452 3.01355 18.7427 3.01355H5.2573C4.05478 3.01355 3.07642 3.99186 3.07642 5.19439V9.76484C2.47357 10.158 2.07396 10.8379 2.07396 11.6097V12.0287C1.45667 12.1913 1 12.7542 1 13.4218V18.3244C1 19.0957 1.60938 19.7272 2.37195 19.7631V20.3433C2.37195 20.6985 2.65997 20.9866 3.01523 20.9866C3.3705 20.9866 3.65852 20.6985 3.65852 20.3433V19.7648H20.3414V20.3433C20.3414 20.6985 20.6295 20.9866 20.9847 20.9866C21.34 20.9866 21.628 20.6985 21.628 20.3433V19.7631C22.3906 19.7271 23 19.0956 23 18.3243V13.4218C23 12.7541 22.5433 12.1913 21.926 12.0288ZM4.36304 5.19439C4.36304 4.70128 4.76419 4.30012 5.2573 4.30012H18.7427C19.2358 4.30012 19.637 4.70128 19.637 5.19439V9.4082H18.7792V8.27434C18.7792 7.48071 18.1336 6.83502 17.3399 6.83502H13.8784C13.0848 6.83502 12.4391 7.48071 12.4391 8.27434V9.4082H11.5609V8.27434C11.5609 7.48071 10.9152 6.83502 10.1216 6.83502H6.6601C5.86643 6.83502 5.22078 7.48071 5.22078 8.27434V9.4082H4.36304V5.19439ZM17.4926 8.2743V9.25545C17.4926 9.33967 17.4241 9.40816 17.3399 9.40816H13.8784C13.7942 9.40816 13.7257 9.33962 13.7257 9.25545V8.2743C13.7257 8.19008 13.7942 8.12159 13.8784 8.12159H17.3399C17.4241 8.12159 17.4926 8.19008 17.4926 8.2743ZM10.2743 8.2743V9.25545C10.2743 9.33967 10.2058 9.40816 10.1216 9.40816H6.6601C6.57588 9.40816 6.50739 9.33962 6.50739 9.25545V8.2743C6.50739 8.19008 6.57593 8.12159 6.6601 8.12159H10.1216C10.2058 8.12159 10.2743 8.19008 10.2743 8.2743ZM3.36057 11.6097C3.36057 11.1052 3.77105 10.6948 4.27559 10.6948H19.7244C20.2289 10.6948 20.6394 11.1053 20.6394 11.6097V11.9813H3.36057V11.6097ZM21.7134 18.3243C21.7134 18.4092 21.6444 18.4782 21.5595 18.4782H2.44044C2.35562 18.4782 2.28657 18.4092 2.28657 18.3243V13.4218C2.28657 13.337 2.35558 13.268 2.44044 13.268H21.5596C21.6444 13.268 21.7134 13.337 21.7134 13.4218V18.3243H21.7134Z'
                fill={color || '#787D8F'}/>
        </svg>
    );
}

export default BedIcon;

BedIcon.propTypes = {
    color : propTypes.string,
    size: propTypes.any
};