import React from 'react';
import propTypes from 'prop-types';

const SaunaIcon = ({ color = '', size, ...props }) => {
    return (
        <svg
            width={size || '28'}
            height={size || '28'}
            viewBox='0 0 28 28'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M25.654 11.4453C25.6941 11.2445 25.6422 11.0362 25.5123 10.8779C25.3825 10.7195 25.1885 10.6277 24.9837 10.6277C10.9791 10.6277 16.449 10.6277 3.01757 10.6277C2.81276 10.6277 2.61876 10.7195 2.48892 10.8779C2.35908 11.0362 2.30709 11.2445 2.34728 11.4453C2.4552 11.9849 5.02341 24.826 5.08165 25.1172C5.1455 25.4367 5.42609 25.6667 5.75194 25.6667H22.2493C22.5752 25.6667 22.8557 25.4367 22.9196 25.1172C23.017 24.6301 25.5872 11.7791 25.654 11.4453ZM4.94516 17.4636H23.0561L22.7826 18.8308H5.2186L4.94516 17.4636ZM7.91323 11.9948L8.1251 14.1136C8.16037 14.4661 8.45755 14.7292 8.8045 14.7292C8.82724 14.7292 8.85021 14.7281 8.87336 14.7258C9.24901 14.6882 9.52309 14.3532 9.48554 13.9776L9.28725 11.9948H13.3009V14.0456C13.3009 14.432 13.6142 14.7453 14.0006 14.7453C14.387 14.7453 14.7003 14.432 14.7003 14.0456V11.9948H18.6978L18.4997 13.976C18.4612 14.3605 18.7418 14.7034 19.1263 14.7418C19.15 14.7441 19.1735 14.7453 19.1967 14.7453C19.5519 14.7453 19.856 14.476 19.8921 14.1152L20.1042 11.9948H24.1498L23.3295 16.0964H4.67172L3.85141 11.9948H7.91323ZM21.6889 24.2995H18.8737L19.0718 22.3184C19.1103 21.9339 18.8298 21.591 18.4453 21.5525C18.0607 21.5143 17.7179 21.7946 17.6794 22.1791L17.4673 24.2995H14.7003V22.2487C14.7003 21.8623 14.387 21.549 14.0006 21.549C13.6142 21.549 13.3009 21.8623 13.3009 22.2487V24.2995H10.5177L10.3058 22.1807C10.2683 21.8051 9.93334 21.5309 9.55763 21.5685C9.18198 21.6061 8.9079 21.9411 8.94545 22.3168L9.1437 24.2995H6.31235L5.49203 20.1979H22.5092L21.6889 24.2995Z'
                fill={color || '#D6AD60'}/>
            <path
                d='M8.78446 9.08403C9.10484 9.29759 9.53961 9.21269 9.75476 8.88998C9.96913 8.56847 9.88222 8.13407 9.56071 7.91969C9.2937 7.74169 9.14057 7.45558 9.14057 7.13465C9.14057 6.81378 9.2937 6.52762 9.56071 6.34966C10.2139 5.91417 10.604 5.18541 10.604 4.40028C10.604 3.61516 10.2139 2.88645 9.56071 2.45095C9.23924 2.23662 8.80479 2.32348 8.59042 2.645C8.37604 2.96652 8.46295 3.40092 8.78446 3.61529C9.05143 3.7933 9.2046 4.07941 9.2046 4.40028C9.2046 4.72116 9.05148 5.00731 8.78446 5.18532C8.13122 5.62082 7.74121 6.34957 7.74121 7.13465C7.74121 7.91974 8.13122 8.64854 8.78446 9.08403Z'
                fill={color || '#D6AD60'}/>
            <path
                d='M13.5696 9.08403C13.89 9.29759 14.3248 9.21269 14.5399 8.88998C14.7543 8.56847 14.6674 8.13407 14.3459 7.91969C14.0789 7.74169 13.9257 7.45558 13.9257 7.13465C13.9257 6.81378 14.0789 6.52762 14.3459 6.34966C14.9991 5.91417 15.3891 5.18541 15.3891 4.40028C15.3891 3.61516 14.9991 2.88645 14.3459 2.45095C14.0243 2.23662 13.5899 2.32348 13.3756 2.645C13.1612 2.96652 13.2481 3.40092 13.5696 3.61529C13.8366 3.7933 13.9898 4.07941 13.9898 4.40028C13.9898 4.72116 13.8366 5.00731 13.5696 5.18532C12.9164 5.62082 12.5264 6.34957 12.5264 7.13465C12.5264 7.91974 12.9164 8.64854 13.5696 9.08403Z'
                fill={color || '#D6AD60'}/>
            <path
                d='M18.3548 9.08403C18.6752 9.29759 19.1099 9.21269 19.3251 8.88998C19.5394 8.56847 19.4525 8.13407 19.131 7.91969C18.864 7.74169 18.7109 7.45558 18.7109 7.13465C18.7109 6.81378 18.864 6.52762 19.131 6.34966C19.7843 5.91417 20.1743 5.18541 20.1743 4.40028C20.1743 3.61516 19.7843 2.88645 19.131 2.45095C18.8095 2.23662 18.3751 2.32348 18.1607 2.645C17.9464 2.96652 18.0333 3.40092 18.3548 3.61529C18.6217 3.7933 18.7749 4.07941 18.7749 4.40028C18.7749 4.72116 18.6218 5.00731 18.3548 5.18532C17.7015 5.62082 17.3115 6.34957 17.3115 7.13465C17.3115 7.91974 17.7015 8.64854 18.3548 9.08403Z'
                fill={color || '#D6AD60'}/>
        </svg>
    );
};

export default SaunaIcon;

SaunaIcon.propTypes = {
    color: propTypes.string,
    size: propTypes.any
};
