import React from 'react';
import propTypes from 'prop-types';

const QuoteIcon = ({size, ...props}) => {
    return (
        <svg
            width={size || '32'}
            height={size || '32'}
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M10.787 15.5084H4.53366C4.64033 9.28183 5.86699 8.25517 9.69366 5.9885C10.1337 5.72183 10.2803 5.16183 10.0137 4.7085C9.76033 4.2685 9.18699 4.12183 8.74699 4.3885C4.24033 7.05517 2.66699 8.68183 2.66699 16.4284V23.6151C2.66699 25.8951 4.52033 27.7351 6.78699 27.7351H10.787C13.1337 27.7351 14.907 25.9618 14.907 23.6151V19.6151C14.907 17.2818 13.1337 15.5084 10.787 15.5084Z'
                fill='#D6AD60'/>
            <path
                d='M25.2115 15.5084H18.9581C19.0648 9.28183 20.2915 8.25517 24.1181 5.9885C24.5581 5.72183 24.7048 5.16183 24.4381 4.7085C24.1715 4.2685 23.6115 4.12183 23.1581 4.3885C18.6515 7.05517 17.0781 8.68183 17.0781 16.4418V23.6284C17.0781 25.9084 18.9315 27.7484 21.1981 27.7484H25.1981C27.5448 27.7484 29.3181 25.9751 29.3181 23.6284V19.6284C29.3315 17.2818 27.5581 15.5084 25.2115 15.5084Z'
                fill='#D6AD60'/>
        </svg>
    );
};

export default QuoteIcon;

QuoteIcon.propTypes = {
    size: propTypes.any
};