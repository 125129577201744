import React from 'react';

function InfoIcon() {
    return (
        <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17316C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8078C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C21.9971 9.34871 20.9426 6.80684 19.0679 4.9321C17.1932 3.05736 14.6513 2.00287 12 2ZM12 20.3333C10.3518 20.3333 8.74066 19.8446 7.37025 18.9289C5.99984 18.0132 4.93174 16.7117 4.30101 15.189C3.67028 13.6663 3.50525 11.9908 3.82679 10.3742C4.14834 8.75774 4.94201 7.27288 6.10745 6.10744C7.27288 4.94201 8.75774 4.14833 10.3743 3.82679C11.9908 3.50525 13.6663 3.67027 15.189 4.301C16.7117 4.93173 18.0132 5.99984 18.9289 7.37025C19.8446 8.74066 20.3333 10.3518 20.3333 12C20.3309 14.2094 19.4522 16.3276 17.8899 17.8899C16.3276 19.4522 14.2094 20.3309 12 20.3333Z'
                fill='#787D8F'/>
            <path
                d='M12 10H11C10.7348 10 10.4804 10.0819 10.2929 10.2278C10.1054 10.3737 10 10.5715 10 10.7778C10 10.9841 10.1054 11.1819 10.2929 11.3277C10.4804 11.4736 10.7348 11.5556 11 11.5556H12V16.2222C12 16.4285 12.1054 16.6263 12.2929 16.7722C12.4804 16.9181 12.7348 17 13 17C13.2652 17 13.5196 16.9181 13.7071 16.7722C13.8946 16.6263 14 16.4285 14 16.2222V11.5556C14 11.143 13.7893 10.7473 13.4142 10.4556C13.0391 10.1639 12.5304 10 12 10Z'
                fill='#787D8F'/>
            <path
                d='M12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8Z'
                fill='#787D8F'/>
        </svg>
    );
}

export default InfoIcon;
