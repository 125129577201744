import React from 'react';
import propTypes from 'prop-types';

const BlanketIcon = ({size, ...props}) => {
    return (
        <svg
            width={size || '24'}
            height={size || '24'}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M19.6562 4.40265V2H6.10156C3.8399 2 2 3.8399 2 6.10156V17.8984C2 20.1601 3.8399 22 6.10156 22H19.0703C20.6858 22 22 20.6858 22 19.0703V7.27344C22 5.85864 20.9919 4.67487 19.6562 4.40265ZM3.17188 6.10156C3.17188 4.48611 4.48611 3.17188 6.10156 3.17188H18.4844V13.7969H6.10156C4.95502 13.7969 3.91681 14.2697 3.17188 15.0307V6.10156ZM19.0703 20.8281H6.10156C4.48611 20.8281 3.17188 19.5139 3.17188 17.8984C3.17188 16.283 4.48611 14.9688 6.10156 14.9688H19.6562V5.61633C20.3383 5.85834 20.8281 6.50958 20.8281 7.27344V16.7281C20.3382 16.3594 19.7293 16.1406 19.0703 16.1406H6.10156C5.13232 16.1406 4.34375 16.9292 4.34375 17.8984C4.34375 18.8677 5.13232 19.6562 6.10156 19.6562H19.6562V18.4844H6.10156C5.77853 18.4844 5.51562 18.2215 5.51562 17.8984C5.51562 17.5754 5.77853 17.3125 6.10156 17.3125H19.0703C20.0396 17.3125 20.8281 18.1011 20.8281 19.0703C20.8281 20.0396 20.0396 20.8281 19.0703 20.8281Z'
                fill='#D6AD60'/>
        </svg>
    );
};

export default BlanketIcon;

BlanketIcon.propTypes = {
    size: propTypes.any
};