import React from 'react';
import propTypes from 'prop-types';

const MinusIcon = ({ stroke=false, ...props }) => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M5 10H15'
                stroke={stroke ? '#D6AD60' : '#D4D7E2'}
                strokeWidth='1.5'
                strokeLinecap='round'
                strokeLinejoin='round'/>
        </svg>
    );
};

export default MinusIcon;

MinusIcon.propTypes = {
    stroke: propTypes.oneOfType([
        propTypes.string,
        propTypes.bool,
    ]),
};