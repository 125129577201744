import React from 'react';

function WhatsappIcon() {
    return (
        <svg
            width={24}
            height={24}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'>
            <path
                d='M19.0262 4.90448C17.1474 3.03268 14.648 2.00119 11.988 2.00012C9.33386 2.00012 6.83051 3.0307 4.93933 4.90204C3.0448 6.77658 2.00049 9.26773 1.99805 11.9075V11.9106V11.9124C1.99835 13.5101 2.41812 15.1219 3.21494 16.5932L2.02536 22.0001L7.49457 20.7561C8.87976 21.4542 10.4253 21.8222 11.9842 21.8228H11.9881C14.6418 21.8228 17.1451 20.7921 19.0366 18.9206C20.9328 17.0445 21.9776 14.5566 21.9788 11.9151C21.9796 9.2923 20.9312 6.80252 19.0262 4.90448ZM11.988 20.2618H11.9845C10.5848 20.2612 9.19791 19.9098 7.974 19.2453L7.71536 19.1049L4.07858 19.9321L4.86853 16.342L4.71625 16.0794C3.95911 14.7737 3.55902 13.3324 3.55902 11.9107C3.56192 7.30911 7.34274 3.5611 11.9877 3.5611C14.2316 3.56201 16.3401 4.43192 17.9246 6.01028C19.533 7.61307 20.4185 9.70993 20.4177 11.9147C20.4159 16.5173 16.6343 20.2618 11.988 20.2618Z'
                fill='#24336B'/>
            <path
                d='M9.26968 7.54163H8.83176C8.67932 7.54163 8.43182 7.59869 8.22247 7.82651C8.01297 8.05447 7.42261 8.60547 7.42261 9.72607C7.42261 10.8467 8.24155 11.9294 8.35568 12.0816C8.46997 12.2336 9.93649 14.6066 12.2592 15.5195C14.1896 16.2782 14.5825 16.1273 15.0013 16.0893C15.4203 16.0515 16.3534 15.5385 16.5439 15.0067C16.7343 14.4749 16.7343 14.019 16.6772 13.9238C16.62 13.8289 16.4676 13.7719 16.2391 13.6581C16.0106 13.5441 14.8906 12.9838 14.6811 12.9077C14.4716 12.8318 14.3193 12.7939 14.1668 13.022C14.0144 13.2496 13.5656 13.7764 13.4323 13.9283C13.2991 14.0805 13.1657 14.0995 12.9371 13.9856C12.7086 13.8713 11.9801 13.6265 11.1071 12.8508C10.4277 12.247 9.95618 11.477 9.82281 11.2491C9.68961 11.0212 9.80862 10.8979 9.92322 10.7843C10.0259 10.6823 10.1646 10.5429 10.2789 10.41C10.393 10.2769 10.4255 10.182 10.5018 10.03C10.578 9.87805 10.5398 9.745 10.4828 9.63116C10.4255 9.51718 9.987 8.39093 9.78375 7.94049H9.78391C9.6127 7.56116 9.4325 7.54834 9.26968 7.54163Z'
                fill='#24336B'/>
        </svg>
    );
}

export default WhatsappIcon;
