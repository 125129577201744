import React from 'react';
import propTypes from 'prop-types';

const JacuzziIcon = ({size, ...props}) => {
    return (
        <svg
            width={size || '24'}
            height={size || '24'}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M21.8485 9.2533C21.7374 9.13053 21.5796 9.06053 21.414 9.06053H2.58596C2.42041 9.06053 2.2626 9.13053 2.15151 9.2533C2.04041 9.37604 1.98643 9.54006 2.00291 9.70479L3.02709 19.9466C3.1442 21.1172 4.11959 21.9999 5.29603 21.9999H18.704C19.8804 21.9999 20.8559 21.1172 20.9729 19.9466L21.9971 9.70479C22.0136 9.54006 21.9596 9.37604 21.8485 9.2533ZM17.2978 10.2324V13.8937H12.5859V10.2324H17.2978ZM3.93947 17.2929H20.0605L19.9428 18.4703H4.0572L3.93947 17.2929ZM18.704 20.8281H5.29603C4.72419 20.8281 4.25009 20.399 4.19322 19.83L4.17443 19.6422H19.8256L19.8068 19.83C19.7499 20.399 19.2758 20.8281 18.704 20.8281ZM20.1777 16.1211H3.82228L3.23342 10.2324H11.4141V14.4797C11.4141 14.8032 11.6764 15.0656 12 15.0656H17.8838C18.2074 15.0656 18.4697 14.8032 18.4697 14.4797V10.2324H20.7666L20.1777 16.1211Z'
                fill='#D6AD60'/>
            <path
                d='M8.41781 6.08477C8.41711 6.51457 8.07016 6.86906 8.06656 6.87258C7.95113 6.98024 7.87664 7.13024 7.88395 7.29301C7.89816 7.61047 8.14141 7.87895 8.46988 7.87895C8.61871 7.87895 8.76422 7.81504 8.8732 7.71344C8.94531 7.64426 9.58012 7.01387 9.58957 6.10379C9.59363 5.70953 9.47461 5.11559 8.88418 4.5252C8.64004 4.28106 8.52156 4.04192 8.52195 3.79422C8.52266 3.36442 8.86961 3.00993 8.8732 3.00641C8.98863 2.89876 9.06312 2.74876 9.05582 2.58598C9.0416 2.26852 8.79836 2.00005 8.46988 2.00005C8.32106 2.00005 8.17555 2.06395 8.06656 2.16555C7.99445 2.23473 7.35965 2.86512 7.3502 3.7752C7.34613 4.16946 7.46516 4.7634 8.05559 5.35379C8.29973 5.59793 8.4182 5.83703 8.41781 6.08477Z'
                fill='#D6AD60'/>
            <path
                d='M11.9481 6.08477C11.9474 6.51457 11.6004 6.86906 11.5968 6.87258C11.4814 6.98024 11.4069 7.13024 11.4142 7.29301C11.4284 7.61047 11.6717 7.87895 12.0002 7.87895C12.149 7.87895 12.2945 7.81504 12.4035 7.71344C12.4756 7.64426 13.1104 7.01387 13.1198 6.10379C13.1239 5.70953 13.0049 5.11559 12.4145 4.5252C12.1703 4.28106 12.0518 4.04192 12.0522 3.79422C12.0529 3.36442 12.3999 3.00993 12.4035 3.00641C12.5189 2.89876 12.5934 2.74876 12.5861 2.58598C12.5719 2.26852 12.3286 2.00005 12.0002 2.00005C11.8513 2.00005 11.7058 2.06395 11.5968 2.16555C11.5247 2.23473 10.8899 2.86512 10.8805 3.7752C10.8764 4.16946 10.9954 4.7634 11.5859 5.35379C11.83 5.59793 11.9485 5.83703 11.9481 6.08477Z'
                fill='#D6AD60'/>
            <path
                d='M15.4784 6.08477C15.4777 6.51457 15.1307 6.86906 15.1271 6.87258C15.0117 6.98024 14.9372 7.13024 14.9445 7.29301C14.9587 7.61047 15.202 7.87895 15.5304 7.87895C15.6793 7.87895 15.8248 7.81504 15.9338 7.71344C16.0059 7.64426 16.6407 7.01387 16.6501 6.10379C16.6542 5.70953 16.5352 5.11559 15.9447 4.5252C15.7006 4.28106 15.5821 4.04192 15.5825 3.79422C15.5832 3.36442 15.9302 3.00993 15.9338 3.00641C16.0492 2.89876 16.1237 2.74876 16.1164 2.58598C16.1021 2.26852 15.8589 2.00005 15.5304 2.00005C15.3816 2.00005 15.2361 2.06395 15.1271 2.16555C15.055 2.23473 14.4202 2.86512 14.4107 3.7752C14.4067 4.16946 14.5257 4.7634 15.1161 5.35379C15.3603 5.59793 15.4787 5.83703 15.4784 6.08477Z'
                fill='#D6AD60'/>
        </svg>
    );
};

export default JacuzziIcon;

JacuzziIcon.propTypes = {
    size: propTypes.any
};