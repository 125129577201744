import axios from 'axios';
import Cookies from 'universal-cookie';

/**
 * Public API connection
 */
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.post['Content-Type'] = 'application/json';

const API_URL = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
});

API_URL.interceptors.request.use(async(config) => {
    config.timeout = 30000;

    if (config.url === 'web/booking/list' 
        || config.url === 'web/booking/get_cart'
        || config.url === 'web/booking/submit'
        || config.url === 'web/booking/detail'
        || config.url === 'web/guest_chat/create'
        || config.url === 'web/booking/add_to_cart'
        // || config.url === 'web/experience/list'
        || config.url === 'web/experience/villa_list'
        || config.url === 'web/experience/detail'
    ) {
        const cookies = new Cookies();
        const credential = await cookies.get('credential');

        const token = credential.data;

        config.headers.Authorization = `Bearer ${token}`;

        return config;
    }

    return config;
});

export { API_URL };