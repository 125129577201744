import React from 'react';

const ThumbIcon = (props) => {
    return (
        <svg
            width='48'
            height='48'
            viewBox='0 0 48 48'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M32.5588 44.2H24.9588C23.8388 44.2 21.3988 43.86 20.0988 42.56L14.0387 37.88L15.8787 35.5L22.0788 40.3C22.5788 40.78 23.8388 41.18 24.9588 41.18H32.5588C34.3588 41.18 36.2988 39.74 36.6988 38.12L41.5388 23.42C41.8588 22.54 41.7988 21.74 41.3788 21.16C40.9388 20.54 40.1388 20.18 39.1588 20.18H31.1588C30.1188 20.18 29.1588 19.74 28.4988 18.98C27.8188 18.2 27.5188 17.16 27.6788 16.08L28.6788 9.65998C28.9188 8.53998 28.1588 7.27998 27.0788 6.91998C26.0988 6.55998 24.8388 7.07998 24.3988 7.71998L16.1987 19.92L13.7188 18.26L21.9188 6.05998C23.1788 4.17998 25.9388 3.27998 28.0988 4.09998C30.5988 4.91998 32.1988 7.67998 31.6388 10.24L30.6588 16.54C30.6388 16.68 30.6388 16.88 30.7788 17.04C30.8788 17.14 31.0188 17.2 31.1788 17.2H39.1788C41.1388 17.2 42.8388 18.02 43.8388 19.44C44.8188 20.82 45.0188 22.64 44.3788 24.4L39.5988 38.96C38.8588 41.86 35.7788 44.2 32.5588 44.2Z'
                fill='#D6AD60'/>
            <path
                d='M10.7617 41.9998H8.76172C5.06172 41.9998 3.26172 40.2598 3.26172 36.6998V17.0998C3.26172 13.5398 5.06172 11.7998 8.76172 11.7998H10.7617C14.4617 11.7998 16.2617 13.5398 16.2617 17.0998V36.6998C16.2617 40.2598 14.4617 41.9998 10.7617 41.9998ZM8.76172 14.7998C6.58172 14.7998 6.26172 15.3198 6.26172 17.0998V36.6998C6.26172 38.4798 6.58172 38.9998 8.76172 38.9998H10.7617C12.9417 38.9998 13.2617 38.4798 13.2617 36.6998V17.0998C13.2617 15.3198 12.9417 14.7998 10.7617 14.7998H8.76172Z'
                fill='#D6AD60'/>
        </svg>
    );
};

export default ThumbIcon;
