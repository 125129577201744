import React, { Fragment, useEffect } from 'react';
import AppRoutes from './routes';
import Interceptors from './api/interceptors';
import Cookies from 'universal-cookie';
import { CounterContext } from './providers/ContextProviders';
import '~/assets/css/global.css';
import '~/assets/css/datepicker.css';

function AppContainer() {
    const cookies = new Cookies();
    const { handleLogin } = React.useContext(CounterContext);

    useEffect(() => {
        loadingAuth();
    }, []);

    const loadingAuth = async () => {
        const isLoggedIn = await cookies.get('credential');

        if (isLoggedIn) {
            handleLogin(isLoggedIn);
        }
    };
    
    return (
        <Fragment>
      
            {/* App Screens */}
            <AppRoutes />
      
            {/* Axios Response Handler */}
            <Interceptors />

        </Fragment>
    );
}

export default AppContainer;