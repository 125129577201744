import React from 'react';
import propTypes from 'prop-types';

const SwimmingPoolIcon = ({ color = '', size, ...props }) => {
    return (
        <svg
            width={size || '24'}
            height={size || '24'}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M1.82085 16.7194C10.1816 14.3428 13.2375 19.1456 22.518 16.7231C22.8625 16.6332 23.0688 16.2811 22.9789 15.9367C22.889 15.5923 22.5369 15.386 22.1925 15.4758C20.3705 15.9515 18.7912 16.1275 17.3611 16.1294L15.4925 13.1177C16.597 14.4902 18.6499 14.5693 19.8527 13.2903C20.3815 12.728 20.6596 11.9934 20.636 11.2218C20.5567 8.63538 17.3877 7.47484 15.6424 9.33062C14.8017 10.2246 14.6492 11.4958 15.1226 12.5214L12.5657 8.40056L16.36 7.30567C17.5383 6.96566 18.0952 5.61687 17.4965 4.54407C17.0618 3.76513 16.1595 3.39379 15.3026 3.64112L9.55836 5.29873C8.06902 5.72854 7.40206 7.45984 8.21984 8.77795L9.95604 11.5762C8.70449 12.3531 4.7349 14.8147 4.69275 14.8439C3.69635 14.9492 2.62926 15.1495 1.46846 15.4795C1.12609 15.5768 0.927398 15.9333 1.02472 16.2757C1.122 16.6182 1.4786 16.8168 1.82085 16.7194ZM16.5814 10.2138C17.1882 9.56866 18.2009 9.5394 18.8442 10.1444C19.4887 10.7504 19.5198 11.7626 18.9137 12.4072C18.3077 13.0515 17.2954 13.0828 16.6509 12.4766C16.0064 11.8705 15.9753 10.8583 16.5814 10.2138ZM11.3911 11.4443L9.31516 8.0984C8.94851 7.50745 9.24701 6.73028 9.91569 6.5373L15.6599 4.8797C15.9378 4.79943 16.23 4.91987 16.3708 5.17227C16.5652 5.52062 16.3836 5.95718 16.0026 6.06714L11.3907 7.39801C10.9802 7.51647 10.7969 7.99472 11.0217 8.35707L15.8035 16.064C12.7963 15.8116 10.3528 14.9046 7.25876 14.7669L11.1833 12.3318C11.4857 12.1441 11.5788 11.7468 11.3911 11.4443Z'
                fill={color || '#787D8F'}/>
            <path
                d='M22.1926 18.4836C13.3134 20.8015 10.1977 16.0058 1.46846 18.4873C1.12609 18.5846 0.927398 18.9411 1.02472 19.2835C1.12205 19.6259 1.47864 19.8246 1.82093 19.7272C10.1817 17.3505 13.2376 22.1534 22.5181 19.7309C22.8626 19.641 23.0689 19.2889 22.979 18.9445C22.8891 18.6 22.537 18.3938 22.1926 18.4836Z'
                fill={color || '#787D8F'}/>
        </svg>
    );
};

export default SwimmingPoolIcon;

SwimmingPoolIcon.propTypes = {
    color: propTypes.string,
    size: propTypes.any
};
