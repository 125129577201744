import React from 'react';

const ChevronRightIcon = (props) => {
    return (
        <svg
            width='20'
            height='20'
            viewBox='0 0 20 20'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M6.87504 16.875C6.7152 16.875 6.5552 16.8139 6.43316 16.6919C6.18895 16.4476 6.18895 16.0522 6.43316 15.8081L12.2413 9.99998L6.43316 4.19186C6.18895 3.94764 6.18895 3.55217 6.43316 3.30811C6.67738 3.06404 7.07285 3.06389 7.31691 3.30811L13.5669 9.55811C13.8111 9.80232 13.8111 10.1978 13.5669 10.4419L7.31691 16.6919C7.19488 16.8139 7.03488 16.875 6.87504 16.875Z'
                fill='#2B2C32'/>
        </svg>
    );
};

export default ChevronRightIcon;
