import React from 'react';
import propTypes from 'prop-types';

const SmartTvIcon = ({size, ...props}) => {
    return (
        <svg
            width={size || '24'}
            height={size || '24'}
            viewBox='0 0 24 24'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <path
                d='M21.4141 4.54449H2.58594C2.26234 4.54449 2 4.80684 2 5.13043V16.9338C2 17.2574 2.26234 17.5198 2.58594 17.5198H5.07445L4.45707 18.5732C4.29348 18.8524 4.38715 19.2114 4.66633 19.375C4.75941 19.4295 4.86137 19.4555 4.96203 19.4555C5.16316 19.4555 5.35906 19.3518 5.46812 19.1657L6.43273 17.5197H17.5673L18.5319 19.1657C18.6409 19.3518 18.8368 19.4555 19.038 19.4555C19.1386 19.4555 19.2406 19.4295 19.3337 19.375C19.6129 19.2114 19.7066 18.8524 19.5429 18.5732L18.9255 17.5198H21.4141C21.7377 17.5198 22 17.2574 22 16.9338V5.13043C22 4.80684 21.7377 4.54449 21.4141 4.54449ZM20.8281 16.3479H3.17188V5.71637H20.8281V16.3479Z'
                fill='#D6AD60'/>
            <path
                d='M12.5745 14.5113C12.4992 14.1075 12.0131 13.9055 11.6745 14.1386C11.2655 14.4077 11.3601 15.0476 11.83 15.1859C12.2464 15.3178 12.6641 14.9386 12.5745 14.5113Z'
                fill='#D6AD60'/>
            <path
                d='M7.32711 9.62711C10.0783 7.51864 13.9212 7.51864 16.6724 9.62711C16.7787 9.70864 16.904 9.74805 17.0284 9.74805C17.2044 9.74801 17.3785 9.66903 17.4939 9.51848C17.6907 9.26165 17.6421 8.89387 17.3853 8.69704C14.2144 6.26684 9.78519 6.26684 6.6143 8.69704C6.35746 8.89387 6.30883 9.26165 6.50566 9.51848C6.7025 9.77532 7.07031 9.82395 7.32711 9.62711Z'
                fill='#D6AD60'/>
            <path
                d='M15.9595 10.5573C13.6281 8.7704 10.3715 8.7704 8.04008 10.5573C7.78324 10.7541 7.73461 11.1219 7.93144 11.3788C8.12832 11.6356 8.49609 11.6842 8.75293 11.4874C10.6646 10.0222 13.335 10.0222 15.2467 11.4874C15.353 11.5689 15.4783 11.6083 15.6027 11.6083C15.7787 11.6083 15.9528 11.5293 16.0682 11.3788C16.265 11.1219 16.2164 10.7541 15.9595 10.5573Z'
                fill='#D6AD60'/>
            <path
                d='M14.2366 12.2094C12.878 11.345 11.1222 11.345 9.76364 12.2094C9.49064 12.3831 9.41013 12.7452 9.58384 13.0183C9.75755 13.2913 10.1197 13.3718 10.3927 13.1981C11.3692 12.5768 12.631 12.5768 13.6075 13.1981C13.705 13.2602 13.8138 13.2898 13.9215 13.2898C14.1151 13.2898 14.3047 13.1938 14.4163 13.0183C14.5901 12.7452 14.5096 12.3831 14.2366 12.2094Z'
                fill='#D6AD60'/>
        </svg>
    );
};

export default SmartTvIcon;

SmartTvIcon.propTypes = {
    size: propTypes.any
};