import React from 'react';
import propTypes from 'prop-types';

const WhiteLogo = (props) => {
    return (
        <svg
            width={props.width || '100'}
            height={props.height || '60'}
            viewBox='0 0 100 60'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <g clipPath='url(#clip0_228_657)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M35.0694 38.2878C36.5164 38.0837 37.8561 37.4405 38.8904 36.4534C39.9246 35.4663 40.5986 34.1877 40.8125 32.8068L41.1041 31.1101L39.3263 31.3951C37.8794 31.5992 36.5397 32.2424 35.5054 33.2295C34.4711 34.2166 33.7972 35.4952 33.5833 36.8761L33.2916 38.5728L35.0694 38.2878ZM34.8541 37.075C35.029 35.9497 35.5784 34.9079 36.4212 34.1036C37.2641 33.2992 38.3556 32.7748 39.5347 32.608C39.3683 33.7434 38.8175 34.7957 37.9662 35.6046C37.1149 36.4134 36.0098 36.9345 34.8194 37.0882L34.8541 37.075Z'
                    fill={props.color || 'white'}/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M60.7083 13.7987C62.1553 13.5946 63.495 12.9514 64.5293 11.9643C65.5636 10.9772 66.2375 9.6986 66.4514 8.31765L66.743 6.62097L64.9653 6.90596C63.5183 7.1101 62.1786 7.75328 61.1443 8.74037C60.11 9.72747 59.4361 11.0061 59.2222 12.387L58.9305 14.0837L60.7083 13.7987ZM60.493 12.5858C60.6679 11.4606 61.2173 10.4188 62.0601 9.61442C62.903 8.81003 63.9945 8.28568 65.1736 8.11882C65.0062 9.24699 64.4592 10.2927 63.615 11.0984C62.7708 11.9041 61.6751 12.4261 60.493 12.5858Z'
                    fill={props.color || 'white'}/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M33.5486 8.31765C33.7625 9.6986 34.4364 10.9772 35.4707 11.9643C36.505 12.9514 37.8447 13.5946 39.2916 13.7987L41.0694 14.0837L40.7777 12.3804C40.5654 11.005 39.8963 9.73089 38.8688 8.74546C37.8412 7.76003 36.5095 7.11526 35.0694 6.90596L33.2916 6.62097L33.5833 8.31765H33.5486ZM34.8194 8.11882C36.0019 8.27752 37.0982 8.79922 37.9426 9.6051C38.787 10.411 39.3337 11.4573 39.5 12.5858C38.3209 12.419 37.2293 11.8946 36.3865 11.0902C35.5437 10.2859 34.9942 9.24409 34.8194 8.11882Z'
                    fill={props.color || 'white'}/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M59.2222 32.8201C59.4372 34.2006 60.1114 35.4787 61.1455 36.4656C62.1796 37.4525 63.5187 38.096 64.9653 38.3011L66.743 38.5861L66.4514 36.8828C66.2372 35.5027 65.563 34.225 64.5286 33.239C63.4943 32.2531 62.1547 31.6112 60.7083 31.4084L58.9305 31.1234L59.2222 32.8201ZM60.493 32.6213C61.6756 32.78 62.7719 33.3017 63.6163 34.1075C64.4607 34.9134 65.0073 35.9597 65.1736 37.0883C63.9945 36.9214 62.903 36.3971 62.0601 35.5927C61.2173 34.7883 60.6679 33.7465 60.493 32.6213Z'
                    fill={props.color || 'white'}/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M27.7847 23.601C28.9584 24.4332 30.3816 24.8827 31.8438 24.8827C33.3059 24.8827 34.7292 24.4332 35.9028 23.601L37.375 22.6002L35.9028 21.5994C34.7287 20.7681 33.3056 20.3193 31.8438 20.3193C30.3819 20.3193 28.9588 20.7681 27.7847 21.5994L26.3195 22.6002L27.7847 23.601ZM28.5347 22.6002C29.492 21.9228 30.6521 21.5571 31.8438 21.5571C33.0355 21.5571 34.1956 21.9228 35.1528 22.6002C34.1982 23.2837 33.0371 23.6531 31.8438 23.6531C30.6504 23.6531 29.4893 23.2837 28.5347 22.6002Z'
                    fill={props.color || 'white'}/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M72.2153 23.601C71.0417 24.4332 69.6184 24.8827 68.1563 24.8827C66.6941 24.8827 65.2708 24.4332 64.0972 23.601L62.625 22.6002L64.0972 21.5994C65.2713 20.7681 66.6944 20.3193 68.1563 20.3193C69.6181 20.3193 71.0412 20.7681 72.2153 21.5994L73.6806 22.6002L72.2153 23.601ZM71.4653 22.6002C70.508 21.9228 69.3479 21.5571 68.1563 21.5571C66.9646 21.5571 65.8045 21.9228 64.8472 22.6002C65.8018 23.2837 66.9629 23.6531 68.1563 23.6531C69.3496 23.6531 70.5107 23.2837 71.4653 22.6002Z'
                    fill={props.color || 'white'}/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M39.7222 25.0724C39.3867 25.7778 38.8982 26.4072 38.2888 26.9191C37.6795 27.4309 36.9633 27.8135 36.1875 28.0415C37.3301 27.9506 38.4175 27.5333 39.3081 26.8441C40.1987 26.1549 40.8512 25.2258 41.1806 24.1776L41.2431 23.9722C40.7598 23.8867 40.2693 23.8445 39.7778 23.8462C37.9865 23.8435 36.2489 24.4298 34.8576 25.5066C33.4662 26.5834 32.5061 28.0848 32.1389 29.7581C33.3739 30.3165 34.7344 30.5749 36.0993 30.5103C37.4642 30.4458 38.7913 30.0602 39.9624 29.388C41.1335 28.7158 42.1123 27.7778 42.8119 26.6574C43.5114 25.5369 43.9099 24.2689 43.972 22.966C44.034 21.6631 43.7577 20.3657 43.1675 19.1894C42.5774 18.013 41.6917 16.9941 40.5894 16.2232C39.487 15.4524 38.2021 14.9536 36.8489 14.7711C35.4958 14.5886 34.1164 14.7281 32.8334 15.1773C32.5903 15.2635 32.3542 15.3562 32.1389 15.4623C32.5073 17.135 33.4677 18.6357 34.8588 19.7123C36.2499 20.7889 37.9868 21.3757 39.7778 21.3741C40.2695 21.3737 40.7601 21.3293 41.2431 21.2416L41.1806 21.0361V20.9367C41.1806 20.8373 41.1111 20.7379 41.0695 20.6451C40.9934 20.4514 40.9029 20.2631 40.7986 20.0817C40.3514 19.2715 39.6979 18.5816 38.8982 18.0755C38.0985 17.5694 37.1783 17.2633 36.2223 17.1855C36.9976 17.4124 37.7136 17.7938 38.3229 18.3045C38.9323 18.8152 39.421 19.4436 39.757 20.148C38.4583 20.1387 37.1925 19.758 36.1229 19.0551C35.0533 18.3521 34.2292 17.3592 33.757 16.2046C34.4023 16.0253 35.071 15.9338 35.7431 15.9328C37.6014 15.9328 39.3837 16.6374 40.6977 17.8915C42.0118 19.1456 42.75 20.8466 42.75 22.6201C42.75 24.3937 42.0118 26.0947 40.6977 27.3488C39.3837 28.6029 37.6014 29.3074 35.7431 29.3074C35.0707 29.3096 34.4016 29.2181 33.757 29.0357C34.2245 27.8831 35.0421 26.89 36.1049 26.1839C37.1677 25.4777 38.4274 25.0907 39.7222 25.0724Z'
                    fill={props.color || 'white'}/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M48.9583 43.802C48.0863 42.682 47.6154 41.3236 47.6154 39.9282C47.6154 38.5327 48.0863 37.1744 48.9583 36.0543L50.0069 34.6559L51.0555 36.0543C51.9276 37.1744 52.3985 38.5327 52.3985 39.9282C52.3985 41.3236 51.9276 42.682 51.0555 43.802L50.0069 45.2005L48.9583 43.802ZM50.0069 43.0862C50.7167 42.1727 51.0999 41.0655 51.0999 39.9282C51.0999 38.7909 50.7167 37.6837 50.0069 36.7701C49.2961 37.683 48.9117 38.7898 48.9105 39.9271C48.9092 41.0644 49.2912 42.172 50 43.0862H50.0069Z'
                    fill={props.color || 'white'}/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M41.7014 36.2465C41.7003 35.2063 41.9144 34.1761 42.3315 33.215C42.7486 32.2539 43.3605 31.381 44.132 30.6462L46.6389 28.2668V21.8579L50 25.0657L56.2153 31.0306C57.2835 32.1922 57.9739 33.6258 58.202 35.1559C58.4302 36.6861 58.1862 38.2465 57.5 39.6465C55.505 39.2481 53.7529 38.1195 52.6195 36.5026C51.4862 34.8857 51.0619 32.9094 51.4375 30.9974C52.5828 31.2805 53.6076 31.8963 54.3702 32.7594C55.1328 33.6226 55.5955 34.6906 55.6945 35.8157C55.4554 35.0772 55.0552 34.3953 54.5202 33.815C53.9852 33.2347 53.3275 32.7691 52.5903 32.4489C52.6047 33.6917 53.0094 34.9018 53.7522 35.9227C54.4951 36.9436 55.5418 37.7282 56.757 38.1752C57.1092 37.0296 57.1346 35.815 56.8306 34.6569C56.5267 33.4988 55.9043 32.4391 55.0278 31.5873L49.1945 25.9008L47.9584 24.6945V29.0025L44.7014 31.9518C43.9476 32.798 43.426 33.8098 43.1836 34.8961C42.9411 35.9824 42.9854 37.1092 43.3125 38.1752C44.5277 37.7282 45.5744 36.9436 46.3173 35.9227C47.0601 34.9018 47.4649 33.6917 47.4792 32.4489C46.7421 32.7691 46.0843 33.2347 45.5493 33.815C45.0143 34.3953 44.6141 35.0772 44.375 35.8157C44.4687 34.7441 44.8926 33.723 45.5933 32.8809C46.294 32.0388 47.2401 31.4134 48.3125 31.0836L48.632 30.9974C48.9957 32.9138 48.5571 34.89 47.4105 36.5011C46.2639 38.1122 44.5007 39.2297 42.5 39.6134C41.9766 38.5611 41.7039 37.4113 41.7014 36.2465Z'
                    fill={props.color || 'white'}/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M51.0417 1.39845C51.9078 2.51712 52.3752 3.87141 52.3752 5.26236C52.3752 6.65331 51.9078 8.0076 51.0417 9.12628L49.9931 10.5247L48.9583 9.12628C48.0857 8.00518 47.6145 6.6457 47.6145 5.24911C47.6145 3.85252 48.0857 2.49304 48.9583 1.37194L50.007 -0.0264893L51.0417 1.39845ZM49.9931 2.11423C49.2816 3.02841 48.8975 4.13689 48.8975 5.27562C48.8975 6.41435 49.2816 7.52283 49.9931 8.437C50.7055 7.52354 51.0908 6.41544 51.0921 5.27671C51.0933 4.13798 50.7104 3.02912 50 2.11423H49.9931Z'
                    fill={props.color || 'white'}/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M58.2986 8.96052C58.3043 9.99845 58.0956 11.0273 57.6845 11.9882C57.2735 12.9492 56.668 13.8234 55.9027 14.5609L53.3958 16.9336V23.3491L50.0347 20.1413L43.7847 14.1765C42.7165 13.0148 42.0261 11.5812 41.798 10.0511C41.5698 8.52094 41.8137 6.9605 42.5 5.56055C44.5008 5.95876 46.2576 7.09135 47.3916 8.71419C48.5257 10.337 48.9462 12.3202 48.5625 14.2361C47.4167 13.9517 46.3917 13.3348 45.6291 12.4705C44.8666 11.6062 44.4041 10.5372 44.3055 9.4112C44.5433 10.1511 44.943 10.8345 45.4781 11.416C46.0132 11.9976 46.6716 12.4641 47.4097 12.7847C47.3963 11.5416 46.9919 10.3311 46.2489 9.31008C45.506 8.28903 44.4587 7.50456 43.243 7.05839C42.8908 8.20398 42.8654 9.41854 43.1694 10.5766C43.4733 11.7347 44.0957 12.7944 44.9722 13.6463L50.8055 19.3328L52.0416 20.539V16.231L55.2986 13.2817C56.0524 12.4355 56.574 11.4238 56.8164 10.3374C57.0589 9.25112 57.0146 8.12431 56.6875 7.05839C55.4717 7.50456 54.4245 8.28903 53.6816 9.31008C52.9386 10.3311 52.5342 11.5416 52.5208 12.7847C53.2589 12.4641 53.9173 11.9976 54.4524 11.416C54.9875 10.8345 55.3872 10.1511 55.625 9.4112C55.5367 10.4793 55.1208 11.4989 54.4292 12.3428C53.7377 13.1867 52.801 13.8175 51.7361 14.1566L51.4166 14.2428C51.0329 12.3268 51.4534 10.3437 52.5875 8.72082C53.7215 7.09798 55.4783 5.96539 57.4791 5.56717C58.0138 6.62589 58.2938 7.78526 58.2986 8.96052Z'
                    fill={props.color || 'white'}/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M60.2847 25.0724C60.618 25.7781 61.105 26.4079 61.7133 26.9198C62.3216 27.4318 63.0372 27.8142 63.8125 28.0415C62.6699 27.9506 61.5825 27.5333 60.6919 26.8441C59.8013 26.1549 59.1489 25.2258 58.8195 24.1776L58.757 23.9722C59.2402 23.8867 59.7308 23.8445 60.2222 23.8462C62.0136 23.8435 63.7512 24.4298 65.1425 25.5066C66.5338 26.5834 67.4939 28.0848 67.8611 29.7581C66.6261 30.3165 65.2656 30.5749 63.9007 30.5103C62.5358 30.4458 61.2088 30.0602 60.0377 29.388C58.8666 28.7158 57.8877 27.7778 57.1882 26.6574C56.4887 25.5369 56.0901 24.2689 56.0281 22.966C55.966 21.6631 56.2424 20.3657 56.8325 19.1894C57.4227 18.013 58.3083 16.9941 59.4107 16.2232C60.5131 15.4524 61.798 14.9536 63.1511 14.7711C64.5043 14.5886 65.8837 14.7281 67.1667 15.1773C67.4028 15.2635 67.6389 15.3562 67.8611 15.4623C67.4927 17.135 66.5323 18.6357 65.1412 19.7123C63.7502 20.7889 62.0132 21.3757 60.2222 21.3741C59.7306 21.3737 59.24 21.3293 58.757 21.2416L58.8195 21.0361L58.8542 20.9367C58.8889 20.8373 58.9236 20.7379 58.9653 20.6451C59.0414 20.4514 59.1319 20.2631 59.2361 20.0817C59.6833 19.2715 60.3368 18.5816 61.1365 18.0755C61.9362 17.5694 62.8564 17.2633 63.8125 17.1855C63.0372 17.4124 62.3212 17.7938 61.7119 18.3045C61.1025 18.8152 60.6137 19.4436 60.2778 20.148C61.5764 20.1387 62.8423 19.758 63.9119 19.0551C64.9815 18.3521 65.8056 17.3592 66.2778 16.2046C65.6325 16.0253 64.9638 15.9338 64.2917 15.9328C62.4333 15.9328 60.6511 16.6374 59.337 17.8915C58.023 19.1456 57.2848 20.8466 57.2848 22.6201C57.2848 24.3937 58.023 26.0947 59.337 27.3488C60.6511 28.6029 62.4333 29.3074 64.2917 29.3074C64.9641 29.3096 65.6332 29.2181 66.2778 29.0357C65.8085 27.8789 64.9867 26.883 63.9184 26.1765C62.8501 25.47 61.5844 25.0854 60.2847 25.0724Z'
                    fill={props.color || 'white'}/>
                <path
                    d='M1.90278 50.1978L6.91667 56.3681L6.77778 53.3857V50.1978H9.00695V59.6885H7.10417L2.15972 53.6176L2.24306 56.7326V59.6885H0V50.1978H1.90278Z'
                    fill={props.color || 'white'}/>
                <path
                    d='M17.9445 50.2177H20.1459V55.5198C20.2245 56.6874 19.8178 57.8375 19.0139 58.7209C18.6032 59.1115 18.1141 59.4191 17.576 59.6252C17.0379 59.8314 16.4617 59.9318 15.882 59.9205C14.6222 60.0171 13.3737 59.6334 12.4098 58.8535C11.9376 58.4323 11.5684 57.917 11.3283 57.3443C11.0883 56.7716 10.9833 56.1554 11.0209 55.5396V50.2375H13.2223V55.5396C13.1957 55.8862 13.2437 56.2342 13.3631 56.5624C13.4825 56.8906 13.6709 57.1921 13.9167 57.4484C14.1743 57.6708 14.4782 57.8388 14.8084 57.9416C15.1387 58.0444 15.4879 58.0796 15.8334 58.0449C17.2223 57.9521 17.9167 57.117 17.9167 55.5396L17.9445 50.2177Z'
                    fill={props.color || 'white'}/>
                <path
                    d='M29.5138 51.3244L28.1249 52.4908C27.8327 52.2187 27.4786 52.0145 27.0902 51.8944C26.6805 51.7635 26.2514 51.6963 25.8194 51.6955C25.3789 51.6804 24.9423 51.7788 24.5555 51.9805C24.4042 52.0477 24.2757 52.1542 24.1848 52.2878C24.0939 52.4214 24.0443 52.5768 24.0416 52.7361C24.0282 52.9077 24.0712 53.0791 24.1645 53.2264C24.2578 53.3736 24.3969 53.4895 24.5624 53.5579C25.0846 53.7401 25.6323 53.8473 26.1874 53.876C28.7337 54.0837 30.0092 55.1088 30.0138 56.9512C30.0278 57.5742 29.8115 58.1819 29.4027 58.6678C29.0008 59.1379 28.4693 59.4914 27.868 59.6885C27.2063 59.8944 26.5153 60.0017 25.8194 60.0066C24.9392 60.0121 24.0679 59.8381 23.2638 59.4963C22.5163 59.2059 21.8876 58.692 21.4722 58.0315L22.7916 56.706C23.1561 57.1486 23.6346 57.4935 24.1805 57.7068C24.7041 57.9255 25.2687 58.0405 25.8402 58.0448C26.3398 58.0642 26.8359 57.9567 27.2777 57.7333C27.4403 57.6598 27.5783 57.5447 27.6764 57.4007C27.7744 57.2568 27.8288 57.0897 27.8333 56.9181C27.8441 56.7427 27.8053 56.5677 27.721 56.4115C27.6367 56.2552 27.51 56.1235 27.3541 56.03C26.8833 55.7897 26.3601 55.6581 25.8263 55.6456C25.3118 55.6041 24.8012 55.5266 24.2986 55.4136C23.8707 55.3101 23.4578 55.1565 23.0694 54.9563C22.6857 54.7548 22.3719 54.4507 22.1666 54.0815C21.9542 53.6559 21.854 53.1872 21.8749 52.7162C21.8663 52.2982 21.968 51.8849 22.1707 51.5143C22.3734 51.1436 22.6705 50.8276 23.0347 50.5953C23.8533 50.0677 24.825 49.7988 25.8124 49.8265C26.5224 49.8131 27.2288 49.9255 27.8958 50.1579C28.5276 50.4136 29.0834 50.8143 29.5138 51.3244Z'
                    fill={props.color || 'white'}/>
                <path
                    d='M45.5764 50.1978L41.9653 59.6753H40.1458L38.0139 54.3732L35.9583 59.6753H34.1389L30.4305 50.1978H32.9167L34.1528 53.4387L35.0486 56.5073L37.2778 50.1978H38.7292L41.0555 56.5073L41.9097 53.4519L43.0903 50.1978H45.5764Z'
                    fill={props.color || 'white'}/>
                <path
                    d='M54.5139 59.6952L53.8195 58.0648H48.7223L48.0278 59.6952H45.6459L50.0486 50.2177H52.4653L56.9236 59.7084L54.5139 59.6952ZM52.9653 56.1825L51.257 52.3319L49.5417 56.1825H52.9653Z'
                    fill={props.color || 'white'}/>
                <path
                    d='M60.1944 52.2059V55.069H62.6666C62.8819 55.0823 63.0978 55.0533 63.3009 54.9838C63.5039 54.9142 63.6899 54.8057 63.8472 54.6648C64.1131 54.3854 64.2605 54.0215 64.2605 53.6441C64.2605 53.2667 64.1131 52.9028 63.8472 52.6235C63.6923 52.4804 63.5081 52.3694 63.3061 52.2975C63.1042 52.2257 62.8888 52.1945 62.6736 52.2059H60.1944ZM62.6666 57.0109H60.1944V59.6951H57.9861V50.2176H62.6736C63.1816 50.1921 63.6897 50.2652 64.1672 50.4327C64.6447 50.6002 65.0816 50.8585 65.4514 51.1919C65.7783 51.5124 66.0347 51.8921 66.2052 52.3088C66.3758 52.7254 66.4571 53.1705 66.4444 53.6176C66.4619 54.0667 66.3835 54.5145 66.214 54.9338C66.0445 55.3531 65.7874 55.7351 65.4583 56.0566C65.0823 56.384 64.6419 56.6369 64.1627 56.8008C63.6835 56.9646 63.175 57.036 62.6666 57.0109Z'
                    fill={props.color || 'white'}/>
                <path
                    d='M75.6805 59.6952L74.9861 58.0648H69.8889L69.1944 59.6952H66.8055L71.1805 50.2177H73.5972L78.0555 59.7084L75.6805 59.6952ZM74.118 56.1825L72.4097 52.3319L70.6944 56.1825H74.118Z'
                    fill={props.color || 'white'}/>
                <path
                    d='M79.1319 50.2176H83.2083C84.1806 50.1897 85.142 50.4191 85.9861 50.8804C86.7022 51.2737 87.2762 51.8654 87.6319 52.577C87.9811 53.3018 88.1544 54.0925 88.1389 54.8901C88.1441 55.5021 88.0456 56.1108 87.8472 56.6928C87.656 57.2485 87.362 57.767 86.9792 58.2238C86.5646 58.7006 86.0325 59.0718 85.4305 59.3041C84.7218 59.5827 83.9609 59.7203 83.1944 59.7084H79.118V50.2176H79.1319ZM81.3194 52.1264V57.7532H83.2083C83.6667 57.763 84.1218 57.6771 84.5417 57.5014C84.8905 57.3657 85.1899 57.1352 85.4028 56.8386C85.5916 56.556 85.7345 56.2476 85.8264 55.924C85.92 55.5844 85.9667 55.2346 85.9653 54.8835C85.9891 54.1806 85.7558 53.4917 85.3055 52.9349C85.0449 52.6599 84.7214 52.4457 84.3602 52.3089C83.9989 52.1721 83.6096 52.1165 83.2222 52.1463L81.3194 52.1264Z'
                    fill={props.color || 'white'}/>
                <path
                    d='M97.5695 59.6952L96.875 58.0648H91.7778L91.0834 59.6952H88.7014L93.125 50.2177H95.5417L100 59.7084L97.5695 59.6952ZM96.0347 56.1825L94.3334 52.3319L92.6111 56.1825H96.0347Z'
                    fill={props.color || 'white'}/>
            </g>
            <defs>
                <clipPath id='clip0_228_657'>
                    <rect width='100' height='60' fill={props.color || 'white'} />
                </clipPath>
            </defs>
        </svg>
    );
};

export default WhiteLogo;

WhiteLogo.propTypes = {
    color: propTypes.string,
    width: propTypes.string,
    height: propTypes.string
};