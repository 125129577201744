import React from 'react';
import propTypes from 'prop-types';

const ColorLogo = (props) => {
    return (
        <svg
            width={props.width || '100'}
            height={props.height || '60'}
            viewBox='0 0 112 68'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
            {...props}>
            <g clipPath='url(#clip0_195_5428)'>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M39.2777 43.3928C40.8983 43.1615 42.3988 42.4325 43.5572 41.3138C44.7156 40.1951 45.4704 38.746 45.71 37.181L46.0366 35.2581L44.0455 35.581C42.4249 35.8124 40.9244 36.5413 39.766 37.66C38.6076 38.7788 37.8528 40.2278 37.6133 41.7929L37.2866 43.7158L39.2777 43.3928ZM39.0366 42.0182C39.2324 40.7429 39.8478 39.5623 40.7918 38.6506C41.7357 37.739 42.9583 37.1447 44.2788 36.9556C44.0925 38.2424 43.4756 39.4351 42.5221 40.3518C41.5687 41.2685 40.331 41.859 38.9977 42.0333L39.0366 42.0182Z'
                    fill='#D6AD60'/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M67.9933 15.6387C69.6139 15.4073 71.1144 14.6784 72.2728 13.5597C73.4312 12.441 74.186 10.9919 74.4255 9.4268L74.7522 7.50391L72.7611 7.82689C71.1405 8.05825 69.64 8.78719 68.4816 9.90589C67.3232 11.0246 66.5684 12.4737 66.3289 14.0388L66.0022 15.9617L67.9933 15.6387ZM67.7522 14.2641C67.948 12.9888 68.5634 11.8081 69.5073 10.8965C70.4513 9.98484 71.6739 9.39058 72.9944 9.20147C72.807 10.4801 72.1943 11.6652 71.2488 12.5783C70.3033 13.4914 69.0761 14.0831 67.7522 14.2641Z'
                    fill='#D6AD60'/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M37.5744 9.4268C37.814 10.9919 38.5688 12.441 39.7272 13.5597C40.8855 14.6784 42.386 15.4073 44.0066 15.6387L45.9977 15.9617L45.6711 14.0312C45.4333 12.4725 44.6839 11.0285 43.533 9.91166C42.3822 8.79483 40.8906 8.0641 39.2777 7.82689L37.2866 7.50391L37.6133 9.4268H37.5744ZM38.9977 9.20147C40.3222 9.38133 41.55 9.97259 42.4957 10.8859C43.4415 11.7993 44.0537 12.985 44.24 14.2641C42.9194 14.075 41.6969 13.4807 40.7529 12.5691C39.8089 11.6574 39.1936 10.4768 38.9977 9.20147Z'
                    fill='#D6AD60'/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M66.3289 37.1961C66.5696 38.7607 67.3248 40.2091 68.483 41.3276C69.6411 42.4462 71.141 43.1755 72.7611 43.408L74.7522 43.7309L74.4255 41.8005C74.1856 40.2363 73.4305 38.7883 72.272 37.6709C71.1136 36.5535 69.6133 35.826 67.9933 35.5962L66.0022 35.2732L66.3289 37.1961ZM67.7522 36.9707C69.0766 37.1506 70.3045 37.7419 71.2502 38.6552C72.1959 39.5685 72.8082 40.7543 72.9944 42.0334C71.6739 41.8443 70.4513 41.25 69.5073 40.3384C68.5634 39.4267 67.948 38.2461 67.7522 36.9707Z'
                    fill='#D6AD60'/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M31.1189 26.7477C32.4333 27.691 34.0274 28.2003 35.665 28.2003C37.3026 28.2003 38.8967 27.691 40.2111 26.7477L41.86 25.6135L40.2111 24.4793C38.8962 23.5372 37.3023 23.0286 35.665 23.0286C34.0277 23.0286 32.4338 23.5372 31.1189 24.4793L29.4778 25.6135L31.1189 26.7477ZM31.9589 25.6135C33.031 24.8458 34.3303 24.4313 35.665 24.4313C36.9997 24.4313 38.299 24.8458 39.3711 25.6135C38.302 26.3881 37.0015 26.8069 35.665 26.8069C34.3285 26.8069 33.028 26.3881 31.9589 25.6135Z'
                    fill='#D6AD60'/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M80.8811 26.7477C79.5667 27.691 77.9726 28.2003 76.335 28.2003C74.6974 28.2003 73.1034 27.691 71.7889 26.7477L70.14 25.6135L71.7889 24.4793C73.1039 23.5372 74.6977 23.0286 76.335 23.0286C77.9723 23.0286 79.5662 23.5372 80.8811 24.4793L82.5222 25.6135L80.8811 26.7477ZM80.0411 25.6135C78.969 24.8458 77.6697 24.4313 76.335 24.4313C75.0003 24.4313 73.701 24.8458 72.6289 25.6135C73.698 26.3881 74.9985 26.8069 76.335 26.8069C77.6716 26.8069 78.972 26.3881 80.0411 25.6135Z'
                    fill='#D6AD60'/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M44.4889 28.4153C44.1132 29.2148 43.566 29.9282 42.8835 30.5083C42.2011 31.0884 41.3989 31.522 40.5301 31.7804C41.8098 31.6773 43.0277 31.2044 44.0251 30.4233C45.0226 29.6423 45.7534 28.5892 46.1223 27.4013L46.1923 27.1685C45.651 27.0715 45.1016 27.0238 44.5512 27.0258C42.5449 27.0226 40.5988 27.6871 39.0405 28.9075C37.4822 30.1278 36.4069 31.8295 35.9956 33.7259C37.3788 34.3587 38.9026 34.6516 40.4313 34.5784C41.96 34.5052 43.4462 34.0683 44.7579 33.3064C46.0695 32.5446 47.1658 31.4815 47.9493 30.2117C48.7328 28.9419 49.1791 27.5047 49.2486 26.0281C49.3181 24.5515 49.0086 23.0812 48.3477 21.7479C47.6867 20.4147 46.6948 19.2599 45.4601 18.3863C44.2255 17.5127 42.7864 16.9474 41.2708 16.7406C39.7553 16.5338 38.2103 16.6919 36.7734 17.2009C36.5012 17.2986 36.2367 17.4038 35.9956 17.5239C36.4082 19.4197 37.4839 21.1205 39.0419 22.3406C40.5999 23.5607 42.5453 24.2258 44.5512 24.224C45.1019 24.2235 45.6513 24.1732 46.1923 24.0738L46.1223 23.841V23.7283C46.1223 23.6156 46.0445 23.5029 45.9978 23.3978C45.9126 23.1782 45.8113 22.9649 45.6945 22.7593C45.1936 21.841 44.4617 21.0591 43.5661 20.4856C42.6704 19.912 41.6398 19.5651 40.5689 19.4769C41.4373 19.7341 42.2392 20.1663 42.9217 20.7451C43.6041 21.324 44.1516 22.0361 44.5278 22.8344C43.0734 22.8239 41.6556 22.3924 40.4577 21.5957C39.2597 20.799 38.3367 19.6737 37.8078 18.3652C38.5306 18.162 39.2795 18.0583 40.0323 18.0572C42.1136 18.0572 44.1097 18.8557 45.5815 20.2771C47.0532 21.6984 47.88 23.6261 47.88 25.6362C47.88 27.6462 47.0532 29.5739 45.5815 30.9953C44.1097 32.4166 42.1136 33.2151 40.0323 33.2151C39.2792 33.2176 38.5298 33.1139 37.8078 32.9071C38.3314 31.6008 39.2471 30.4754 40.4375 29.6751C41.6279 28.8748 43.0387 28.4361 44.4889 28.4153Z'
                    fill='#24336B'/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M54.8333 49.6422C53.8566 48.3728 53.3292 46.8334 53.3292 45.2519C53.3292 43.6704 53.8566 42.1309 54.8333 40.8615L56.0078 39.2766L57.1822 40.8615C58.1589 42.1309 58.6864 43.6704 58.6864 45.2519C58.6864 46.8334 58.1589 48.3728 57.1822 49.6422L56.0078 51.2271L54.8333 49.6422ZM56.0078 48.831C56.8028 47.7956 57.2319 46.5408 57.2319 45.2519C57.2319 43.9629 56.8028 42.7081 56.0078 41.6727C55.2117 42.7073 54.7812 43.9617 54.7798 45.2506C54.7784 46.5396 55.2062 47.7948 56 48.831H56.0078Z'
                    fill='#D6AD60'/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M46.7056 41.0795C46.7043 39.9006 46.9441 38.733 47.4112 37.6438C47.8784 36.5546 48.5637 35.5652 49.4278 34.7325L52.2356 32.0359V24.7725L56 28.4079L62.9611 35.1681C64.1575 36.4847 64.9307 38.1094 65.1863 39.8436C65.4418 41.5777 65.1686 43.3462 64.4 44.9329C62.1655 44.4813 60.2032 43.2022 58.9339 41.3698C57.6645 39.5373 57.1893 37.2975 57.61 35.1306C58.8927 35.4514 60.0404 36.1493 60.8946 37.1275C61.7487 38.1058 62.267 39.3162 62.3778 40.5913C62.1101 39.7543 61.6619 38.9815 61.0626 38.3238C60.4634 37.6662 59.7267 37.1385 58.9011 36.7756C58.9172 38.1841 59.3705 39.5555 60.2025 40.7125C61.0345 41.8695 62.2068 42.7588 63.5678 43.2653C63.9623 41.967 63.9908 40.5905 63.6503 39.278C63.3099 37.9655 62.6128 36.7645 61.6311 35.7991L55.0978 29.3544L53.7133 27.9873V32.8697L50.0656 36.2122C49.2213 37.1712 48.6371 38.3179 48.3656 39.5491C48.094 40.7802 48.1437 42.0573 48.51 43.2653C49.871 42.7588 51.0433 41.8695 51.8753 40.7125C52.7073 39.5555 53.1606 38.1841 53.1767 36.7756C52.3511 37.1385 51.6144 37.6662 51.0152 38.3238C50.416 38.9815 49.9677 39.7543 49.7 40.5913C49.805 39.3768 50.2797 38.2195 51.0645 37.2652C51.8493 36.3108 52.9089 35.6021 54.11 35.2282L54.4678 35.1306C54.8752 37.3025 54.384 39.5421 53.0998 41.3681C51.8155 43.194 49.8408 44.4605 47.6 44.8953C47.0138 43.7028 46.7083 42.3996 46.7056 41.0795Z'
                    fill='#24336B'/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M57.1667 1.5849C58.1368 2.85273 58.6603 4.38759 58.6603 5.964C58.6603 7.54041 58.1368 9.07527 57.1667 10.3431L55.9922 11.928L54.8333 10.3431C53.856 9.07252 53.3282 7.53179 53.3282 5.94898C53.3282 4.36618 53.856 2.82544 54.8333 1.55486L56.0078 -0.0300293L57.1667 1.5849ZM55.9922 2.39612C55.1954 3.43219 54.7652 4.68846 54.7652 5.97903C54.7652 7.26959 55.1954 8.52586 55.9922 9.56193C56.7902 8.52667 57.2218 7.27083 57.2232 5.98027C57.2246 4.6897 56.7957 3.43299 56 2.39612H55.9922Z'
                    fill='#D6AD60'/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M65.2945 10.1553C65.3009 11.3316 65.0672 12.4976 64.6068 13.5867C64.1464 14.6758 63.4682 15.6665 62.6112 16.5024L59.8034 19.1914V26.4624L56.0389 22.8269L49.0389 16.0667C47.8426 14.7502 47.0693 13.1255 46.8138 11.3913C46.5583 9.65712 46.8315 7.88862 47.6 6.302C49.841 6.75331 51.8086 8.03691 53.0787 9.87613C54.3488 11.7154 54.8198 13.9629 54.39 16.1343C53.1068 15.812 51.9588 15.1128 51.1047 14.1332C50.2506 13.1537 49.7326 11.9422 49.6223 10.6661C49.8886 11.5047 50.3362 12.2791 50.9355 12.9382C51.5349 13.5973 52.2722 14.126 53.0989 14.4893C53.0839 13.0806 52.631 11.7087 51.7989 10.5515C50.9668 9.39429 49.7939 8.50522 48.4323 7.99956C48.0378 9.29789 48.0093 10.6744 48.3498 11.9869C48.6902 13.2994 49.3873 14.5004 50.3689 15.4658L56.9023 21.9105L58.2867 23.2776V18.3952L61.9345 15.0527C62.7788 14.0937 63.3629 12.947 63.6345 11.7158C63.906 10.4847 63.8564 9.2076 63.49 7.99956C62.1284 8.50522 60.9555 9.39429 60.1234 10.5515C59.2913 11.7087 58.8384 13.0806 58.8234 14.4893C59.6501 14.126 60.3874 13.5973 60.9868 12.9382C61.5861 12.2791 62.0337 11.5047 62.3 10.6661C62.2012 11.8765 61.7354 13.0321 60.9608 13.9885C60.1863 14.945 59.1372 15.6599 57.9445 16.0442L57.5867 16.1418C57.157 13.9704 57.6279 11.7229 58.8981 9.88365C60.1682 8.04443 62.1358 6.76082 64.3767 6.30951C64.9755 7.50939 65.2891 8.82335 65.2945 10.1553Z'
                    fill='#24336B'/>
                <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M67.5189 28.4153C67.8921 29.2151 68.4376 29.9289 69.1189 30.5092C69.8001 31.0894 70.6016 31.5228 71.47 31.7804C70.1903 31.6773 68.9724 31.2044 67.9749 30.4233C66.9775 29.6423 66.2467 28.5892 65.8778 27.4013L65.8078 27.1685C66.349 27.0715 66.8984 27.0238 67.4489 27.0258C69.4551 27.0226 71.4012 27.6871 72.9595 28.9075C74.5178 30.1278 75.5932 31.8295 76.0044 33.7259C74.6212 34.3587 73.0975 34.6516 71.5688 34.5784C70.0401 34.5052 68.5538 34.0683 67.2421 33.3064C65.9305 32.5446 64.8342 31.4815 64.0507 30.2117C63.2673 28.9419 62.8209 27.5047 62.7514 26.0281C62.6819 24.5515 62.9914 23.0812 63.6524 21.7479C64.3133 20.4147 65.3053 19.2599 66.5399 18.3863C67.7746 17.5127 69.2137 16.9474 70.7292 16.7406C72.2447 16.5338 73.7897 16.6919 75.2266 17.2009C75.4911 17.2986 75.7555 17.4038 76.0044 17.5239C75.5918 19.4197 74.5161 21.1205 72.9581 22.3406C71.4001 23.5607 69.4547 24.2258 67.4489 24.224C66.8982 24.2235 66.3487 24.1732 65.8078 24.0738L65.8778 23.841L65.9166 23.7283C65.9555 23.6156 65.9944 23.5029 66.0411 23.3978C66.1263 23.1782 66.2276 22.9649 66.3444 22.7593C66.8453 21.841 67.5772 21.0591 68.4729 20.4856C69.3685 19.912 70.3992 19.5651 71.47 19.4769C70.6016 19.7341 69.7997 20.1663 69.1172 20.7451C68.4348 21.324 67.8873 22.0361 67.5111 22.8344C68.9656 22.8239 70.3833 22.3924 71.5813 21.5957C72.7792 20.799 73.7022 19.6737 74.2311 18.3652C73.5083 18.162 72.7594 18.0583 72.0066 18.0572C69.9253 18.0572 67.9292 18.8557 66.4574 20.2771C64.9857 21.6984 64.1589 23.6261 64.1589 25.6362C64.1589 27.6462 64.9857 29.5739 66.4574 30.9953C67.9292 32.4166 69.9253 33.2151 72.0066 33.2151C72.7597 33.2176 73.5091 33.1139 74.2311 32.9071C73.7055 31.5961 72.785 30.4674 71.5885 29.6667C70.392 28.8661 68.9745 28.4302 67.5189 28.4153Z'
                    fill='#24336B'/>
                <path
                    d='M2.13111 56.8909L7.74667 63.8839L7.59111 60.5038V56.8909H10.0878V67.6471H7.95667L2.41889 60.7667L2.51222 64.297V67.6471H0V56.8909H2.13111Z'
                    fill='#24336B'/>
                <path
                    d='M20.0977 56.9133H22.5633V62.9224C22.6513 64.2457 22.1958 65.5492 21.2955 66.5504C20.8355 66.9931 20.2878 67.3417 19.685 67.5753C19.0823 67.8089 18.437 67.9227 17.7877 67.9099C16.3768 68.0194 14.9785 67.5845 13.8988 66.7006C13.37 66.2232 12.9565 65.6393 12.6876 64.9902C12.4188 64.3411 12.3012 63.6428 12.3433 62.9449V56.9359H14.8088V62.9449C14.7792 63.3376 14.8328 63.7321 14.9666 64.1041C15.1003 64.476 15.3113 64.8177 15.5866 65.1082C15.8752 65.3602 16.2155 65.5506 16.5854 65.6671C16.9552 65.7836 17.3464 65.8235 17.7333 65.7842C19.2888 65.679 20.0666 64.7326 20.0666 62.9449L20.0977 56.9133Z'
                    fill='#24336B'/>
                <path
                    d='M33.0555 58.1678L31.4999 59.4898C31.1727 59.1813 30.7761 58.95 30.3411 58.8138C29.8822 58.6654 29.4016 58.5893 28.9177 58.5884C28.4244 58.5712 27.9354 58.6828 27.5022 58.9114C27.3327 58.9876 27.1888 59.1083 27.087 59.2597C26.9852 59.4111 26.9296 59.5872 26.9266 59.7677C26.9116 59.9622 26.9597 60.1565 27.0642 60.3234C27.1688 60.4903 27.3246 60.6216 27.5099 60.6991C28.0948 60.9056 28.7081 61.0271 29.3299 61.0596C32.1818 61.295 33.6103 62.4568 33.6155 64.5449C33.6312 65.2509 33.3888 65.9397 32.931 66.4903C32.4809 67.0231 31.8856 67.4238 31.2122 67.6471C30.4711 67.8804 29.6972 68.002 28.9177 68.0076C27.9319 68.0139 26.956 67.8167 26.0555 67.4292C25.2182 67.1002 24.5141 66.5177 24.0488 65.7692L25.5266 64.267C25.9348 64.7686 26.4708 65.1594 27.0822 65.4012C27.6686 65.6491 28.301 65.7794 28.9411 65.7843C29.5006 65.8062 30.0562 65.6844 30.5511 65.4312C30.7331 65.3479 30.8877 65.2174 30.9975 65.0543C31.1074 64.8912 31.1682 64.7019 31.1733 64.5073C31.1854 64.3085 31.142 64.1102 31.0476 63.9332C30.9531 63.7561 30.8112 63.6068 30.6366 63.5008C30.1093 63.2285 29.5233 63.0793 28.9255 63.0652C28.3492 63.0181 27.7773 62.9303 27.2144 62.8023C26.7352 62.6849 26.2728 62.5108 25.8377 62.284C25.4079 62.0556 25.0565 61.711 24.8266 61.2925C24.5887 60.8102 24.4765 60.279 24.4999 59.7452C24.4903 59.2715 24.6042 58.803 24.8312 58.383C25.0582 57.9629 25.3909 57.6048 25.7988 57.3415C26.7158 56.7435 27.804 56.4388 28.9099 56.4702C29.7051 56.455 30.4962 56.5824 31.2433 56.8458C31.9509 57.1356 32.5734 57.5897 33.0555 58.1678Z'
                    fill='#24336B'/>
                <path
                    d='M51.0456 56.8909L47.0012 67.6321H44.9634L42.5756 61.623L40.2734 67.6321H38.2356L34.0823 56.8909H36.8667L38.2512 60.5639L39.2545 64.0417L41.7512 56.8909H43.3767L45.9823 64.0417L46.9389 60.5789L48.2612 56.8909H51.0456Z'
                    fill='#24336B'/>
                <path
                    d='M61.0555 67.6545L60.2777 65.8067H54.5688L53.7911 67.6545H51.1233L56.0544 56.9133H58.7611L63.7544 67.6696L61.0555 67.6545ZM59.3211 63.6735L57.4077 59.3094L55.4866 63.6735H59.3211Z'
                    fill='#24336B'/>
                <path
                    d='M67.4178 59.1667V62.4116H70.1867C70.4278 62.4267 70.6696 62.3938 70.897 62.315C71.1244 62.2362 71.3327 62.1131 71.5089 61.9534C71.8068 61.6369 71.9718 61.2245 71.9718 60.7967C71.9718 60.3689 71.8068 59.9565 71.5089 59.64C71.3355 59.4778 71.1291 59.352 70.9029 59.2706C70.6767 59.1892 70.4355 59.1538 70.1945 59.1667H67.4178ZM70.1867 64.6125H67.4178V67.6545H64.9445V56.9133H70.1945C70.7634 56.8844 71.3325 56.9673 71.8673 57.1571C72.4021 57.3469 72.8914 57.6396 73.3056 58.0175C73.6718 58.3807 73.9589 58.8111 74.1499 59.2833C74.3409 59.7555 74.432 60.2599 74.4178 60.7667C74.4374 61.2756 74.3496 61.7832 74.1597 62.2584C73.9699 62.7336 73.6819 63.1665 73.3133 63.5308C72.8922 63.9019 72.399 64.1886 71.8623 64.3742C71.3256 64.5599 70.756 64.6409 70.1867 64.6125Z'
                    fill='#24336B'/>
                <path
                    d='M84.7623 67.6545L83.9845 65.8067H78.2756L77.4978 67.6545H74.8223L79.7223 56.9133H82.4289L87.4223 67.6696L84.7623 67.6545ZM83.0123 63.6735L81.0989 59.3094L79.1778 63.6735H83.0123Z'
                    fill='#24336B'/>
                <path
                    d='M88.6277 56.9132H93.1933C94.2822 56.8816 95.359 57.1415 96.3044 57.6644C97.1064 58.1101 97.7493 58.7807 98.1477 59.5873C98.5388 60.4087 98.7329 61.3048 98.7155 62.2087C98.7213 62.9023 98.611 63.5922 98.3889 64.2518C98.1747 64.8816 97.8454 65.4692 97.4166 65.9869C96.9523 66.5273 96.3563 66.948 95.6822 67.2113C94.8884 67.527 94.0361 67.6829 93.1777 67.6694H88.6122V56.9132H88.6277ZM91.0777 59.0765V65.4536H93.1933C93.7067 65.4647 94.2163 65.3673 94.6866 65.1682C95.0773 65.0144 95.4127 64.7532 95.6511 64.417C95.8626 64.0967 96.0226 63.7472 96.1255 63.3805C96.2304 62.9956 96.2826 62.5992 96.2811 62.2012C96.3077 61.4046 96.0465 60.6238 95.5422 59.9929C95.2502 59.6811 94.888 59.4383 94.4834 59.2834C94.0788 59.1284 93.6427 59.0653 93.2089 59.099L91.0777 59.0765Z'
                    fill='#24336B'/>
                <path
                    d='M109.278 67.6545L108.5 65.8067H102.791L102.013 67.6545H99.3456L104.3 56.9133H107.007L112 67.6696L109.278 67.6545ZM107.559 63.6735L105.653 59.3094L103.724 63.6735H107.559Z'
                    fill='#24336B'/>
            </g>
            <defs>
                <clipPath id='clip0_195_5428'>
                    <rect width='112' height='68' fill='white' />
                </clipPath>
            </defs>
        </svg>
    );
};

export default ColorLogo;

ColorLogo.propTypes = {
    width: propTypes.string,
    height: propTypes.string
};  