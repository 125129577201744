import React, { Fragment } from 'react';
import { useState } from 'react';
import propTypes from 'prop-types';
import { ChevronDownIcon } from '~/assets/icons';

const DropdownComponent = ({placeholder='', icon=null, selectedOption, setSelectedOption, listOption, labelColor, bgProfile}) => {
    const [isOpen, setIsOpen] = useState(false);

    const handleSelect = (option) => {
        setSelectedOption(option);
        setIsOpen(false);
    };

    return (
        <Fragment>
            <div className='relative flex-1'>
                <div className='flex flex-row flex-1 items-center max-sm:justify-between gap-x-3 sm:pl-2 xl:pl-5 cursor-pointer' onClick={() => setIsOpen(!isOpen)}>
                    <div className='flex flex-row items-center gap-x-3'> 
                        <div className={`flex justify-center items-center rounded-full p-[5px] ${bgProfile}`}>
                            {icon}
                        </div>
                        <p className={`mdText max-sm:hidden max-lg:text-sm lg:text-base ${labelColor ? '' : 'text-colors-gray300'} py-2`} style={labelColor ? { color: labelColor } : {}}>{selectedOption || placeholder}</p>
                    </div>
                    <ChevronDownIcon color={labelColor} className='max-lg:hidden' />
                </div>

                {/* Dropdown options */}
                {isOpen && (
                    <div className='absolute z-10 mt-2 right-0 py-1 w-40 bg-white border border-gray-200 rounded shadow-lg'>
                        {listOption.map((option, index) => (
                            <div
                                key={index}
                                className='cursor-pointer p-3 mx-1 hover:bg-colors-blue100'
                                onClick={() => handleSelect(option)}>
                                <p className='mdText'>{option}</p>
                            </div>
                        ))}
                    </div>
                )}
            </div>

        </Fragment>
    );
};

export default DropdownComponent;

DropdownComponent.propTypes = {
    placeholder: propTypes.string,
    icon: propTypes.element,
    selectedOption: propTypes.string,
    labelColor: propTypes.string,
    setSelectedOption: propTypes.func,
    listOption: propTypes.array,
    bgProfile: propTypes.string
};  