import React, { Fragment, useEffect } from 'react';
import propTypes from 'prop-types';
import CloseIcon from '~/assets/icons/CloseIcon';

function Modal({ renderElement, visibility, onClose, modalTitle = 'This is modal', modalHeight, modalHeader = true }) {
    useEffect(() => {
        if (visibility) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "auto"; 
        }
    }, [visibility]);

    return (
        <Fragment>
            {/* backdrop filter */}
            <div onClick={onClose} className={`bg-neutral-950/[0.5] fixed top-0 left-0 w-[100%] h-[100%] z-50 ${visibility ? 'opacity-100' : 'opacity-0 invisible'} transition-opacity ease-in-out duration-200`} />

            {/* modal content */}
            <div className={`fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] max-sm:translate-y-[0] max-sm:top-[auto] max-sm:bottom-[0] ${visibility ? 'opacity-100' : 'opacity-0 invisible'} transition-opacity ease-in-out duration-200 z-[51]`}>
                <div className={`bg-white shadow-lg w-[500px] ${modalHeight} max-sm:w-screen`}>
                    {
                        modalHeader && 
                            <div className='sticky flex justify-between items-center p-[24px]'>
                                <h3 className='__text-title-1 __ff-neuton __fw-400 tracking-wider'>{modalTitle}</h3>
                                <div className='cursor-pointer' onClick={onClose}>
                                    <CloseIcon />
                                </div>
                            </div>
                    }
                    {renderElement}
                </div>
            </div>
        </Fragment>
    );
}

export default Modal;

Modal.propTypes = {
    modalHeader: propTypes.bool,
    renderElement: propTypes.element,
    modalTitle: propTypes.string,
    visibility: propTypes.bool,
    onClose: propTypes.func,
    modalHeight: propTypes.string,
};